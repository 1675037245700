import { useState, useEffect } from 'react';
import { useUrl } from '@cs/hooks';
import { Input, InputProps, Chips } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

import { ValuesInputContainerStyles } from './Styles';

const KEY_ENTER = 13;

type IProps = InputProps & {
  value?: string[];
  onChange(values: string[]): void;
};

export const InputMultiple = ({
  value = [],
  errorLabel,
  onChange,
  ...rest
}: IProps) => {
  const { t } = useTranslation();
  const { error, clearError, validUrl } = useUrl();
  const [values, setValues] = useState<string[]>(value ? value : []);
  const [innerValue, setInnerValue] = useState<string>();

  useEffect(() => {
    onChange(values);
  }, [values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addValue = (event: any) => {
    const value = event.target.value;
    if (event.keyCode === KEY_ENTER) {
      const isValid = validUrl(value);

      if (isValid) {
        setValues((values: string[]) =>
          values.includes(value) ? values : [...values, value],
        );
        setInnerValue('');
        clearError();
      }
    } else {
      clearError();
    }
  };

  const removeValue = (value: string) => {
    setValues((values: string[]) => values.filter((item) => item !== value));
  };

  return (
    <div>
      <Input
        onKeyDown={addValue}
        {...rest}
        errorLabel={
          errorLabel ? errorLabel : error ? t('Invalid url') : undefined
        }
        value={innerValue}
        onChange={(event) => setInnerValue(event?.target.value)}
      />
      <ValuesInputContainerStyles flexDirection="row" columnGap="1">
        {values.map((item) => (
          <Chips
            closeable
            label={item}
            key={item}
            onClick={() => removeValue(item)}
          />
        ))}
      </ValuesInputContainerStyles>
    </div>
  );
};
