import { useEffect, useState } from 'react';
import { StateType } from '@cs/state/model';
import { useLocation } from 'react-router-dom';

type TData = {
  id: string;
};

export function useScrollPosition<T extends TData>(
  route: string,
  list: T[] = [],
) {
  const location = useLocation();

  const [scrollPosition, setScrollPosition] = useState<number>();
  const [state, setState] = useState<StateType>({});

  useEffect(() => {
    const state = location.state as StateType;
    setState(state);
  }, []);

  const comesFromDetail = () => {
    return state?.from === route;
  };

  useEffect(() => {
    if (list.length && comesFromDetail()) {
      const numRow = list.findIndex((item) => item.id === state.id) || 0;
      if (numRow >= 0) {
        setScrollPosition(numRow);
      }

      window.history.replaceState(null, '');
      setState({});
    }
  }, [list]);

  return {
    scrollPosition,
  };
}
