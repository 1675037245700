import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { MaxItemsPag } from '@cs/state/constants';
import { ClientsDto } from '@cs/state/model';
import { listClients } from '@cs/state/queries';
import { usePaginate } from './usePaginate';

export type listClientsDto = {
  clients: ClientsDto[];
  clients_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export function useClientsPaginate() {
  const {
    search,
    changePage,
    offset,
    handleSearch,
    currentPage,
    getQueryVariables,
  } = usePaginate();

  const { loading, data, error, refetch } = useQuery<listClientsDto>(
    listClients,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...getQueryVariables(),
        offset: offset,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const clients = useMemo(() => (data ? data.clients : []), [data]);
  const total = useMemo(
    () => (data ? data.clients_aggregate.aggregate.count : 0),
    [data],
  );

  const totalPages = useMemo(
    () => Math.ceil(total / MaxItemsPag),
    [offset, MaxItemsPag, total],
  );

  const updateClients = () => refetch(getQueryVariables());

  return {
    data,
    clients,
    handleSearch,
    total,
    currentPage,
    totalPages,
    changePage,
    variables: getQueryVariables(),
    updateClients,
    clientError: error,
    search,
    loading,
  };
}
