import { gql } from '@apollo/client';
import { apiKeysFragment } from '../fragments/apiKeys';

export const insertApiKey = gql`
  mutation insertApiKey($apiKey: ApiKeyInput!) {
    insertApiKey(apikey: $apiKey) {
      id
      api_key
      name
    }
  }
`;

export const deleteApiKey = gql`
  mutation deleteApiKey($id: uuid!) {
    update_api_keys_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const undoDeleteApiKey = gql`
  ${apiKeysFragment}
  mutation undoDeleteApiKey($id: uuid!) {
    update_api_keys_by_pk(pk_columns: { id: $id }, _set: { deleted_at: null }) {
      ...apiKeysFields
      api_keys_services {
        service_id
      }
    }
  }
`;

export const updateApiKey = gql`
  mutation updateApiKey($id: uuid!, $apiKey: api_keys_set_input) {
    update_api_keys_by_pk(pk_columns: { id: $id }, _set: $apiKey) {
      id
    }
  }
`;

export const updateApiKeyServices = gql`
  mutation updateApiKeyServices(
    $apiKey: uuid!
    $services: [api_keys_services_insert_input!]!
  ) {
    delete_api_keys_services(where: { apikey_id: { _eq: $apiKey } }) {
      affected_rows
    }
    insert_api_keys_services(objects: $services) {
      affected_rows
    }
  }
`;
