import {
  FlexContainer,
  InphiniteThemeBaseColors,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const TimelineStyles = styled(FlexContainer)<{
  $width?: string;
  $background?: InphiniteThemeBaseColors;
}>`
  height: 3.2rem;
  padding: 0 1.2rem;
  background-color: ${setColorTheme('grey300')};
  position: relative;
  border-radius: 3.2rem;
  overflow: hidden;

  > * {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: ${(props) => props.$width};
    background-color: ${(props) =>
      props.$background ? setColorTheme(`${props.$background}400`) : ''};
  }
`;
