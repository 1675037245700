import { useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  Service,
  Services,
  Components,
  ClientService,
  ComponentsDto,
} from '@cs/state/model';
import {
  insertClientService,
  insertComponentService,
} from '@cs/state/mutations';
import {
  getAllServicesByClient,
  getAllServices,
  getComponentServices,
  getClientComponentsService,
  getServicesByClient,
  getClientComponentServices,
  getClientServiceByServiceId,
  listComponents,
} from '@cs/state/queries';

import { DropdownSearchOption } from '@facephi/ui-react';

export function useClientServicebyServiceId() {
  const [createClientService] = useMutation(insertClientService);
  const [createComponentsService] = useMutation(insertComponentService);

  const [getClientService, { data: clientService }] =
    useLazyQuery<ClientService>(getClientServiceByServiceId, {
      fetchPolicy: 'network-only',
    });

  const getAndCreateClientService = async (
    clientId: string,
    serviceId: string,
    components: string[],
  ) => {
    const response = await getClientService({
      variables: {
        clientId,
        serviceId,
      },
    });

    if (!response.data?.service) {
      const clientService = await createClientService({
        variables: {
          clientService: {
            client_id: clientId,
            service_id: serviceId,
          },
        },
      });

      const componentsServices = components.map((item) => ({
        component_id: item,
        service_id: clientService.data.service.id,
      }));

      await createComponentsService({
        variables: {
          componentsServices,
        },
      });

      return response.data?.service;
    }

    return response.data?.service;
  };

  return {
    getClientService,
    getAndCreateClientService,
    clientService,
  };
}

export function useComponents() {
  const [getComponents, { data }] = useLazyQuery<ComponentsDto>(listComponents);

  const components = useMemo(() => (data ? data.components : []), [data]);

  return {
    getComponents,
    components,
  };
}

export function useClientServices(clientId: string | undefined) {
  const { data, loading } = useQuery<{
    services: [{ service: Service; id: string }];
  }>(getAllServicesByClient, {
    variables: {
      clientId,
    },
    fetchPolicy: 'network-only',
  });

  const services = useMemo(
    () =>
      data
        ? data.services.map((item) => ({ ...item.service, id: item.id }))
        : [],
    [data],
  );

  return {
    services,
    loading,
  };
}

export function useServices() {
  const { data, refetch: updateServices } = useQuery<Services>(getAllServices, {
    fetchPolicy: 'network-only',
  });

  const services: DropdownSearchOption[] = useMemo(() => {
    return (
      data?.services?.map((service) => ({
        name: service.name,
        value: service.id,
      })) || []
    );
  }, [data]);

  const servicesLicenses: DropdownSearchOption[] = useMemo(() => {
    return (
      data?.services
        ?.filter((service) => service.license)
        .map((service) => ({
          name: service.name,
          value: service.id,
        })) || []
    );
  }, [data]);

  return {
    services,
    servicesLicenses,
    updateServices,
  };
}

export function useServicesAvailable(clientId: string | undefined) {
  const { data, refetch: updateServices } = useQuery<Services>(
    getServicesByClient,
    {
      variables: {
        clientId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const services: DropdownSearchOption[] = useMemo(() => {
    return (
      data?.services?.map((service) => ({
        name: service.name,
        value: service.id,
      })) || []
    );
  }, [data]);

  return {
    services,
    updateServices,
  };
}

export function useComponentServices() {
  const [getComponents, { data }] = useLazyQuery<Components>(
    getComponentServices,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getClientComponents, { data: services }] = useLazyQuery<Components>(
    getClientComponentServices,
    {
      fetchPolicy: 'network-only',
    },
  );

  const components: DropdownSearchOption[] = useMemo(() => {
    return (
      data?.components?.map((c) => ({
        name: c.component.name,
        value: c.component.id,
      })) || []
    );
  }, [data]);

  const componentsClients: string[] = useMemo(() => {
    return services?.components?.map((c) => c.component.id) || [];
  }, [services]);

  return {
    components,
    componentsClients,
    getComponents,
    getClientComponents,
  };
}

export function useComponentsClientsService() {
  const [getComponents, { data }] = useLazyQuery<Components>(
    getClientComponentsService,
    {
      fetchPolicy: 'network-only',
    },
  );

  const components = useMemo(() => {
    return (
      data?.components?.map((c) => ({
        name: c.component.name,
        id: c.component.id,
        schema: c.component.schema,
      })) || []
    );
  }, [data]);

  return {
    components,
    getComponents,
  };
}
