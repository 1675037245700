import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ApplicationDto,
  ApplicationIcon,
  ApplicationsQueryDto,
} from '@cs/state/model';
import {
  insertApplicationClient,
  deleteApplicationClient,
} from '@cs/state/mutations';
import { getApplicationsByClientId } from '@cs/state/queries';
import {
  CardBase,
  Checkbox,
  IconWrapper,
  Label,
  useToast,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CardApplication, CardContainer, ColumnGap } from './Styles';

export const Applications = () => {
  const routeParams = useParams();
  const { toastManager } = useToast();
  const { t } = useTranslation();

  const [insertApplication] = useMutation(insertApplicationClient);
  const [deleteApplication] = useMutation(deleteApplicationClient);

  const { data, refetch } = useQuery<ApplicationsQueryDto>(
    getApplicationsByClientId,
    {
      variables: {
        clientId: routeParams.id,
      },
    },
  );

  const applications = useMemo(
    () =>
      data
        ? data.applications.map((item) => ({
            name: item.name,
            id: data.client_applications.find((i) => i.name === item.name)?.id,
            active: data.client_applications.find((i) => i.name === item.name)
              ? true
              : false,
          }))
        : [],
    [data],
  );

  const onSelectApplication = async (application: ApplicationDto) => {
    application.active
      ? await deleteApplication({
          variables: {
            id: application.id,
          },
          onCompleted: () => {
            toastManager({
              type: 'success',
              message: t('The application has been removed from this client'),
              duration: 3000,
              testId: 'toast-assign-application',
            });
            refetch();
          },
        })
      : await insertApplication({
          variables: {
            clientId: routeParams.id,
            application: application.name,
          },
          onCompleted: () => {
            toastManager({
              type: 'success',
              message: t('The application has been assigned to this customer'),
              duration: 3000,
              testId: 'toast-assign-application',
            });
            refetch();
          },
        });
  };

  const getIcon = (applicationName: string) =>
    ApplicationIcon[applicationName]
      ? ApplicationIcon[applicationName].icon
      : 'Check';

  const getIconColor = (applicationName: string) =>
    ApplicationIcon[applicationName]
      ? ApplicationIcon[applicationName].color
      : 'grey';

  return (
    <CardBase flex="1" flexDirection="column">
      <CardContainer gap={ColumnGap}>
        {applications.map((item) => (
          <CardApplication
            key={item.name}
            onClick={() => onSelectApplication(item)}
            active={item.active}
          >
            <IconWrapper
              iconName={getIcon(item.name)}
              color={getIconColor(item.name)}
              invertBackground
              size="XL"
            />

            <Label fontSize="16" semibold>
              {t(item.name)}
            </Label>
            <Checkbox checked={item.active} />
          </CardApplication>
        ))}
      </CardContainer>
    </CardBase>
  );
};
