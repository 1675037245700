export enum LicenseService {
  sdkIos = 'SDK IOS',
  sdkWeb = 'SDK WEB',
  sdkAndroid = 'SDK ANDROID',
}

export enum ServicesDto {
  SdkWeb = '8f88bc71-33e6-41ce-aa91-f7744c928219',
  SdkIos = '8f88bc71-33e6-41ce-aa91-f7744c928218',
  SdkAndroid = 'ddd9dcfa-ba44-45d4-941c-ebb0f06b3f3b',
}

export enum TrackingComponentsDto {
  trackingMobile = 'b83224ad-fab1-45d8-a9da-364ecb317ac6',
  trackingWeb = 'b83224ad-fab1-45d8-a9da-364ecb317cb4',
}

export enum TechnlologiesDto {
  angular = 'angular',
  react = 'react',
  vue = 'vue',
  javascript = 'javascript',
  ios = 'ios',
  android = 'android',
  cordova = 'cordova',
  flutter = 'flutter',
  capacitor = 'capacitor',
  reactNative = 'react-native',
}

export const TechnlologiesServices: { [key: string]: string } = {
  [TechnlologiesDto.angular]: ServicesDto.SdkWeb,
  [TechnlologiesDto.react]: ServicesDto.SdkWeb,
  [TechnlologiesDto.vue]: ServicesDto.SdkWeb,
  [TechnlologiesDto.javascript]: ServicesDto.SdkWeb,
  [TechnlologiesDto.ios]: ServicesDto.SdkIos,
  [TechnlologiesDto.android]: ServicesDto.SdkAndroid,
  [TechnlologiesDto.capacitor]: ServicesDto.SdkIos,
  [TechnlologiesDto.flutter]: ServicesDto.SdkIos,
  [TechnlologiesDto.reactNative]: ServicesDto.SdkIos,
  [TechnlologiesDto.cordova]: ServicesDto.SdkIos,
};

export const Platforms = {
  [LicenseService.sdkIos]: 'ios',
  [LicenseService.sdkWeb]: 'web',
  [LicenseService.sdkAndroid]: 'android',
};

export type Service = {
  id: string;
  name: string;
  license: boolean;
  components?: string[];
  serviceId: string;
};

export type ServiceForm = {
  id: string;
  components?: string[];
};

export type Component = {
  id: string;
  name: string;
  schema: Record<string, unknown>;
};

export type Components = {
  components: [
    {
      component: Component;
    },
  ];
};

export type ClientService = {
  service: {
    serviceId: string;
    clientId: string;
    id: string;
  };
};

export type Services = {
  services: Service[];
};

export type ServiceListType = {
  id: string;
  service: Service;
};

export type ServiceDto = {
  clients_services: ServiceListType[];
  clients_services_aggregate: {
    aggregate: {
      count: number;
    };
  };
};
