import {
  FlexContainer,
  Label,
  LottieAnimation,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { ASIDENAV_WIDTH } from '../../state/constants';

export const LayoutStyles = styled(FlexContainer)`
  background-color: ${setColorTheme('grey50')};
  flex: 1;
  min-width: 1280px;
  overflow-x: auto;
`;

export const LayoutWrapper = styled(FlexContainer)`
  flex: 1;
  margin-left: ${ASIDENAV_WIDTH};
  padding: 0 2.4rem;
`;

export const LayoutHeader = styled(FlexContainer)<{ $hasGoBack?: boolean }>`
  height: 8.8rem;
  h1 {
    margin-bottom: ${(props) => (props.$hasGoBack ? 0 : '1.6rem')};
  }
`;

export const LayoutContent = styled(FlexContainer)`
  flex: 1;
  padding-top: 3.2rem;
  padding-bottom: 3.6rem;
`;

export const LayoutError = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0;
  background-color: ${setColorTheme('grey50')};

  img {
    margin-top: 5.6rem;
  }

  button {
    margin-top: 4.2rem;
  }
`;

export const LayoutErrorContent = styled(FlexContainer)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5.6rem 0;
`;

export const LayoutErrorTitle = styled(Label)`
  margin: 0 0 1.8rem;
`;

export const LayoutErrorAnimation = styled(LottieAnimation)`
  height: 100%;
`;
