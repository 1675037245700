/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { NewLicenseDto } from '@cs/state/model';
import { Label } from '@facephi/ui-react';
import { UseFormSetError, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Component } from '../../state/model';
import { FormComponent } from './FormComponent';
import { FieldComponent } from './Styles';
import { Switch } from './Switch';

type IProps = {
  components: Component[];
  onChange: (data: any) => void;
  setError: UseFormSetError<NewLicenseDto>;
  clearErrors: UseFormClearErrors<NewLicenseDto>;
  errorLabel?: string;
  value: { [key: string]: any };
};

export const FormLicense = ({
  components,
  onChange,
  setError,
  clearErrors,
  value,
  errorLabel,
}: IProps) => {
  const { t } = useTranslation();

  const dataComponents = useMemo(
    () =>
      components.reduce((obj: { [key: string]: any | boolean }, item) => {
        const key = item.name;

        obj[key] = value ? (value[item.name] ? true : false) : false;
        return obj;
      }, {}),
    [value],
  );

  const [selectedComponent, setSelectedComponent] = useState<string[]>(
    value ? Object.keys(value) : [],
  );

  const onAddComponent = (name: string) => {
    setSelectedComponent((values) =>
      !values.includes(name) ? [...values, name] : values,
    );
    const existData = data[name];
    !existData && setData((data) => ({ ...data, [name]: '' }));
  };

  const onDeleteComponent = (name: string) => {
    setSelectedComponent((values) => values.filter((item) => item !== name));

    const newObject = data;
    delete newObject[name];
    setData(newObject);
  };

  const onChangeComponent = (value: boolean | undefined, name: string) => {
    value ? onAddComponent(name) : onDeleteComponent(name);
  };

  const [data, setData] = useState(value ? value : dataComponents);
  const [errors, setErrors] = useState(dataComponents);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    if (value) {
      setSelectedComponent(Object.keys(value));
    }
  }, [value]);

  const haveErrors = useMemo(() => {
    return Object.values(errors).some((value) => value === true);
  }, [errors]);

  const onChangeForm = (formData: any, name: string) => {
    if (haveErrors) {
      setError('license', { type: 'custom', message: 'License is invalid' });
    } else {
      clearErrors('license');
    }

    setData((data) => ({ ...data, [name]: formData }));
  };

  const onUpdateErrors = (name: string, errors: any[]) => {
    setErrors((e) => ({
      ...e,
      [name]: errors.length ? true : false,
    }));
  };

  return (
    <div>
      {errorLabel && (
        <Label fontSize="12" color="error400">
          {t(errorLabel)}
        </Label>
      )}
      {components.length === 0 && (
        <Label fontSize="12">
          {t('Select a service to display the available components')}
        </Label>
      )}

      {components.map((component) => (
        <FieldComponent key={component.id}>
          <Switch
            labelRight={`Component: ${component.name}`}
            onChange={(value) => onChangeComponent(value, component.name)}
            checked={selectedComponent.includes(component.name)}
          />
          {selectedComponent.includes(component.name) && (
            <div>
              <Label fontSize="12" semibold>
                {t('Properties')}
              </Label>

              {component.schema ? (
                <FormComponent
                  schema={component.schema}
                  value={value ? value[component.name] : undefined}
                  onChange={(values) => onChangeForm(values, component.name)}
                  onError={(errors) => onUpdateErrors(component.name, errors)}
                />
              ) : (
                <Label fontSize="12">
                  {t('This component does not need to add properties')}
                </Label>
              )}
            </div>
          )}
        </FieldComponent>
      ))}
    </div>
  );
};
