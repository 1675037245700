/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useQuery, DocumentNode } from '@apollo/client';
import { useEmptyState } from '@cs/hooks';
import { MaxItemsPag } from '@cs/state/constants';
import {
  OptionMenu,
  TablePagination,
  ButtonIcon,
  OptionMenuItem,
  IconName,
} from '@facephi/ui-react';

export type Columns = {
  accessorKey: string;
  header: string;
};

export type CellProps = {
  row: { id: string; original: { [key: string]: any } };
};

export type Mapping = {
  [key: string]: string;
};

export type Action = {
  testId?: string;
  iconName: IconName;
  onClick: (props: CellProps) => void;
  label: string;
  key: string;
};

type IProps = {
  columns: Columns[];
  query: DocumentNode;
  variables: { [key: string]: number | string | boolean };
  mapping?: Mapping[];
  search: string;
  actions?: Action[];
};

export const TableCommon = ({
  columns,
  query,
  variables,
  search,
  mapping,
  actions,
}: IProps) => {
  const { data, loading, refetch, error } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      offset: 0,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const results = useMemo(
    () =>
      data
        ? mapping
          ? data.items.map((item: any) => {
              const transformedItem = { ...item };
              mapping.forEach((mappingItem) => {
                const [newKey, oldKey] = Object.entries(mappingItem)[0];
                const value = oldKey
                  .split('.')
                  .reduce((obj, key) => obj && obj[key], transformedItem);
                transformedItem[newKey] = value;
              });
              return transformedItem;
            })
          : data.items
        : [],
    [data],
  );
  const total = useMemo(() => (data ? data.total.aggregate.count : 0), [data]);

  const forceUpdateQuery = () => {
    refetch(variables);
  };

  const finalColumns = useMemo(
    () =>
      actions
        ? [
            ...columns,
            {
              header: 'Actions',
              accesor: 'actions',
              cell: (props: any) => {
                return (
                  <OptionMenu
                    hidePanelOnClick
                    widthAuto
                    renderItem={
                      <ButtonIcon variant="text" iconName="ListPlus" />
                    }
                    testId={`option-menu-${props.row.id}`}
                  >
                    {actions.map((action) => (
                      <OptionMenuItem
                        key={action.key}
                        testId={action.testId}
                        iconName={action.iconName}
                        onClick={() => action.onClick(props)}
                      >
                        {action.label}
                      </OptionMenuItem>
                    ))}
                  </OptionMenu>
                );
              },
            },
          ]
        : columns,
    [columns],
  );

  const onChangePage = (page: number) => {
    refetch({
      ...variables,
      offset: MaxItemsPag * (page - 1),
    });
  };

  const { emptyState } = useEmptyState({
    data: results || [],
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(search.length),
    onRetry: forceUpdateQuery,
  });

  return (
    <TablePagination
      columns={finalColumns}
      data={results}
      emptyStateData={emptyState}
      loading={loading}
      onChangePage={onChangePage}
      totalElements={total}
      itemsPerPage={MaxItemsPag}
    />
  );
};
