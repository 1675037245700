import { useEffect, useState } from 'react';
import { CardLeftUserIdElements } from '@cs/components';
import { CaptureSides, SecurityProvider, Environment } from '@cs/state/model';
import { Dropdown, DropdownOption } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { SecurityConfiguration } from '../../state/model';
import { MiniLabel, FieldSeparator, CustomSwitch } from './Styles';

enum Mode {
  manual = 'manual',
  auto = 'auto',
}

type IProps = {
  onChange(value: SecurityConfiguration): void;
  value: SecurityConfiguration;
};

export const SecurityOptions = ({ onChange, value }: IProps) => {
  const { t } = useTranslation();

  const [configProvider, setConfigProvider] = useState<SecurityConfiguration>(
    value
      ? value
      : {
          auto: {
            enabled: false,
            side: CaptureSides.frontBack,
          },
          manual: {
            enabled: false,
            side: CaptureSides.frontBack,
            provider: SecurityProvider.veriff,
            environment: Environment.development,
          },
        },
  );

  useEffect(() => {
    onChange(configProvider);
  }, [configProvider]);

  const onEnabledMode = (value: boolean, mode: Mode) => {
    setConfigProvider((config) => ({
      ...config,
      [mode]: { ...config[mode], enabled: value },
    }));
  };

  const onSetValue = (value: string, name: string, mode: Mode) => {
    setConfigProvider((config) => ({
      ...config,
      [mode]: { ...config[mode], [name]: value },
    }));
  };

  const optionsCaptureSide: DropdownOption[] = Object.values(CaptureSides).map(
    (item) => ({
      name: t(item),
      value: item,
    }),
  );

  const optionsSecurityProvider: DropdownOption[] = Object.values(
    SecurityProvider,
  ).map((item) => ({
    name: t(item),
    value: item,
  }));

  const optionsJumio: DropdownOption[] = [
    {
      name: t('Development'),
      value: 'development',
    },
    {
      name: t('Production'),
      value: 'production',
    },
  ];

  return (
    <>
      <MiniLabel fontSize="12">{t('Mode Auto')}</MiniLabel>

      <CustomSwitch
        testId="check-auto"
        labelLeft={t('Enable')}
        onChange={(value) => onEnabledMode(value as boolean, Mode.auto)}
        checked={configProvider.auto.enabled}
      ></CustomSwitch>

      <FieldSeparator show={configProvider.auto.enabled}>
        <Dropdown
          options={optionsCaptureSide}
          label={t('Sides')}
          name={'side'}
          onChange={(value) => onSetValue(value as string, 'side', Mode.auto)}
          value={configProvider.auto.side}
        />
      </FieldSeparator>

      <MiniLabel fontSize="12">{t('Mode Manual')}</MiniLabel>

      <CustomSwitch
        testId="check-manual"
        labelLeft={t('Enable')}
        onChange={(value) => onEnabledMode(value as boolean, Mode.manual)}
        checked={configProvider.manual.enabled}
      ></CustomSwitch>

      <FieldSeparator show={configProvider.manual.enabled}>
        <CardLeftUserIdElements columnGap="7">
          <Dropdown
            options={optionsSecurityProvider}
            label={t('Provider')}
            name={'Provider'}
            onChange={(value) =>
              onSetValue(value as string, 'provider', Mode.manual)
            }
            value={configProvider.manual.provider}
          />

          <Dropdown
            options={optionsCaptureSide}
            label={t('Sides')}
            name={'side'}
            onChange={(value) =>
              onSetValue(value as string, 'side', Mode.manual)
            }
            value={configProvider.manual.side}
          />

          <Dropdown
            options={optionsJumio}
            label={t('Environment')}
            name={'developtment'}
            onChange={(value) =>
              onSetValue(value as string, 'environment', Mode.manual)
            }
            value={configProvider.manual.environment}
          />
        </CardLeftUserIdElements>
      </FieldSeparator>
    </>
  );
};
