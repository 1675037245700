import { ModalStateProps } from '@facephi/ui-react';
import { Hasura } from '../constants';
import { RoleDto, Roles } from './role';

export type listUsersDto = {
  clients_by_pk: {
    users: UserListDto[];
    users_aggregate: {
      aggregate: {
        count: number;
      };
    };
  };
};

export type User = {
  email: string;
  id: string;
  avatar?: string;
  lastName?: string;
  firstName?: string;
  password: string;
  name?: string;
  metadata?: UserMetadataDto;
};

export type ModalCreateUserProps = ModalStateProps & {
  className?: string;
  onSave(newUser: User): void;
};

export type ModalAssignUserProps = ModalStateProps &
  ModalCreateUserProps & {
    clientId: string;
  };

export type UserPersonalInformationDto = {
  avatar?: string;
  email?: string;
  name: string;
  id: string;
  password?: string;
  role: string;
  connection: string;
  active?: boolean;
  lastName?: string;
  firstName?: string;
  metadata?: UserMetadataDto;
  language?: string;
  timezone?: string;
};

export enum SecurityProvider {
  veriff = 'veriff',
  jumio = 'jumio',
}

export enum CaptureSides {
  front = 'FRONT',
  back = 'BACK',
  frontBack = 'FRONT+BACK',
}

export enum Environment {
  development = 'development',
  production = 'production',
}

export type SecurityConfiguration = {
  auto: {
    enabled: boolean;
    side: CaptureSides;
  };
  manual: {
    enabled: boolean;
    provider: SecurityProvider;
    side: CaptureSides;
    environment: Environment;
  };
};

export type UserMetadataDto = {
  enable_video?: boolean;
  pad?: boolean;
  antifraud?: boolean;
  jumioEnvironment?: string;
  securityProvider?: SecurityProvider;
  security?: boolean;
  liveness?: boolean;
  securityConfig?: SecurityConfiguration;
  timeout_images?: boolean;
};

export type TenantDto = {
  id: string;
  name: string;
};

export type UserProfileDto = {
  email?: string;
  name: string;
  tenants: TenantDto[];
  role: RoleDto;
};

export type UserListDto = {
  id: string;
  email: string;
  name: string;
  tenant?: string;
  role: string;
  avatar?: string;
  users_tenants?: [{ tenant: TenantDto }];
  active?: boolean;
};

export type UserDto = {
  email?: string;
  name?: string;
  fullName?: string;
  surname?: string;
  id?: string;
  emailVerified?: boolean;
  avatar?: string;
  language?: string;
  timezone?: string;
  role?: string;
  [Hasura.claims]?: {
    [Hasura.allowedRoles]: string[];
    [Hasura.defaultRole]: Roles;
  };
};
