import { forwardRef, Ref } from 'react';
import { FlexContainer } from '@facephi/ui-flex-container';
import { Label } from '@facephi/ui-label';
import { SwitchProps } from './model';
import {
  ErrorLabel,
  HiddenCheckbox,
  SwitchBox,
  SwitchBoxContainer,
  SwitchStyles,
} from './StylesSwitch';

export const Switch = forwardRef(
  (
    {
      labelLeft,
      labelRight,
      checked = false,
      disabled,
      id,
      className,
      testId,
      name,
      errorLabel,
      onChange,
    }: SwitchProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleCheck = () => {
      onChange && onChange(!checked);
    };

    const innerLabel = (label: string, id?: string) => {
      return (
        <Label id={id} as="label" fontSize="14">
          {label}
        </Label>
      );
    };

    return (
      <SwitchStyles
        id={id}
        isDisabled={disabled}
        checked={checked}
        disabled={disabled}
        className={className}
        onClick={handleCheck}
        data-test={testId}
        type="button"
      >
        <FlexContainer columnGap="1.6" alignItems="center">
          <HiddenCheckbox
            type="checkbox"
            readOnly
            disabled={disabled}
            checked={checked}
            id={id}
            name={name}
            ref={ref}
          />
          {labelLeft && innerLabel(labelLeft, id)}
          <SwitchBoxContainer>
            <SwitchBox />
          </SwitchBoxContainer>
          {labelRight && innerLabel(labelRight, id)}
        </FlexContainer>
        {errorLabel && (
          <ErrorLabel
            fontSize="8"
            color="error400"
            textAlign={labelLeft ? 'right' : 'left'}
          >
            {errorLabel}
          </ErrorLabel>
        )}
      </SwitchStyles>
    );
  },
);
Switch.displayName = 'Switch';
