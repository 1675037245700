/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode, useMutation } from '@apollo/client';
import { NewLicenseDto } from '@cs/state/model';
import { insertLicense, modifyLicense } from '@cs/state/mutations';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

export function useLicense(query?: DocumentNode, variables?: any) {
  const [createLicense] = useMutation(insertLicense);
  const [editLicense] = useMutation(modifyLicense);
  const { toastManager } = useToast();
  const { t } = useTranslation();

  const toArrayLiteral = (arr: string[]) =>
    JSON.stringify(arr).replace('[', '{').replace(']', '}');

  const onEditLicense = async (license: NewLicenseDto, id: string) => {
    await editLicense({
      variables: {
        licenseId: id,
        license: {
          service_id: license.service,
          licenses: license.license,
          name: license.name,
          origins: license.origins
            ? toArrayLiteral(license.origins)
            : undefined,
          description: license.description,
          valid_until: license.endDate,
          bundle_id: license.bundleId,
        },
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          duration: 3000,
          testId: 'toast-edit-license-error',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('License successfully edited'),
          duration: 3000,
          testId: 'toast-edit-license',
        });
      },
    });
  };

  const onInsertLicense = async (license: NewLicenseDto, clientId?: string) => {
    await createLicense({
      variables: {
        license: {
          service_id: license.service,
          client_id: clientId,
          licenses: license.license,
          name: license.name,
          origins: license.origins
            ? toArrayLiteral(license.origins)
            : undefined,
          apikey: license.apikey,
          description: license.description,
          valid_until: license.endDate,
          bundle_id: license.bundleId,
        },
      },
      refetchQueries: query && [
        {
          query: query,
          variables: variables,
        },
      ],
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          duration: 3000,
          testId: 'toast-create-license-error',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('License successfully created'),
          duration: 3000,
          testId: 'toast-create-license',
        });
      },
    });
  };

  return {
    onInsertLicense,
    onEditLicense,
  };
}
