import { useState } from 'react';
import { RoutesName } from '@cs/state/constants';
import {
  FlexContainer,
  Icon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from '../../providers';
import { ModalLogOut } from '../modalLogOut';
import { AvatarImage } from './Styles';

type HeaderProps = {
  image?: string;
};

export const Header = ({ image = '/Avatar.png' }: HeaderProps) => {
  const { signoutRedirect } = useAuth();
  const { t } = useTranslation();
  const [showLogOut, setShowLogOut] = useState<boolean>(false);
  const { userData: user, idToken } = useAuthService();
  const navigate = useNavigate();

  const handleKeyCloakLogout = () => {
    signoutRedirect({
      id_token_hint: idToken ? idToken : '',
    });
  };

  return (
    <>
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        columnGap="0.4"
      >
        <OptionMenu
          hidePanelOnClick
          positionX={PositionX.end}
          renderItem={
            <FlexContainer
              as="button"
              alignItems="center"
              justifyContent="center"
              data-test="user-menu"
              columnGap="1"
            >
              {image && <AvatarImage src={image} />}
              <Label fontSize="14" semibold>
                {user?.name}
              </Label>
              <Icon iconName="CaretDown" size="24" />
            </FlexContainer>
          }
        >
          <OptionMenuItem onClick={() => navigate(RoutesName.profile)}>
            {t('My profile')}
          </OptionMenuItem>
          <OptionMenuItem
            onClick={() => setShowLogOut(!showLogOut)}
            color="error"
          >
            {t('LogOut')}
          </OptionMenuItem>
        </OptionMenu>
      </FlexContainer>
      <ModalLogOut
        show={showLogOut}
        onChangeShow={(showLogOut: boolean) => setShowLogOut(showLogOut)}
        onLogOut={handleKeyCloakLogout}
      />
    </>
  );
};
