import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment usersFields on users {
    id
    name
    email
    avatar
    active
    lastName: last_name
    firstName: first_name
    metadata
    timezone
    language
  }
`;

export const userTenantFragment = gql`
  fragment userTenantFields on tenants {
    id
    name
  }
`;
