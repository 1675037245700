import { gql } from '@apollo/client';

export const listComponents = gql`
  query listComponents($components: [uuid!]!) {
    components(where: { id: { _in: $components } }) {
      id
      name
    }
  }
`;
