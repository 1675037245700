import { gql } from '@apollo/client';

export const insertClient = gql`
  mutation createClient($client: clients_insert_input!) {
    insert_clients_one(object: $client) {
      id
      name
      description
      created_at
      avatar
      active
      tenants_aggregate {
        aggregate {
          count
        }
      }
      users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const removeClient = gql`
  mutation deleteClient($id: uuid!) {
    update_clients_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const undoRemoveClient = gql`
  mutation undoDeleteClient($id: uuid!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: { deleted_at: null }) {
      id
    }
  }
`;

export const updateClient = gql`
  mutation updateClient($id: uuid!, $client: clients_set_input!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $client) {
      id
    }
  }
`;

export const insertClientService = gql`
  mutation insertClientService($clientService: clients_services_insert_input!) {
    service: insert_clients_services_one(object: $clientService) {
      clientId: client_id
      serviceId: service_id
      id
    }
  }
`;
