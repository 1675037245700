import { Label } from '@facephi/ui-label';
import { setColorTheme, styled } from '@facephi/ui-theme';

export const SwitchBox = styled('span', {
  width: '2.4rem',
  height: '2.4rem',
  borderRadius: '100%',
  transition: 'all 0.2s ease-in-out',
  position: 'absolute',
  top: '50%',
  backgroundColor: setColorTheme('grey600'),
  border: `2px solid ${setColorTheme('grey600')}`,
  transform: 'translate(-85%, -50%)',
});

export const SwitchBoxContainer = styled('div', {
  border: 'none',
  width: '4rem',
  height: '1.6rem',
  borderRadius: '2rem',
  position: 'relative',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: setColorTheme('grey400'),
});

export const SwitchStyles = styled('button', {
  display: 'inline-flex',
  flexDirection: 'column',
  rowGap: '0.8rem',
  label: { cursor: 'inherit' },
  variants: {
    isDisabled: {
      true: {
        cursor: 'default',
        [`${SwitchBox}`]: {
          borderColor: setColorTheme('grey300'),
          backgroundColor: setColorTheme('grey300'),
        },
        [`${SwitchBoxContainer}`]: {
          backgroundColor: setColorTheme('grey200'),
        },
      },
    },
    checked: {
      true: {
        '&:hover&:not(:disabled)': {
          [`${SwitchBox}`]: {
            backgroundColor: setColorTheme('primary500'),
            borderColor: setColorTheme('primary500'),
          },
        },
        '&:active&:not(:disabled)': {
          [`${SwitchBox}`]: {
            borderColor: setColorTheme('primary600'),
          },
        },
        [`${SwitchBox}`]: {
          borderColor: setColorTheme('primary400'),
          backgroundColor: setColorTheme('primary400'),
          transform: 'translate(-15%, -50%)',
        },
      },
    },
  },
  compoundVariants: [
    {
      checked: true,
      isDisabled: true,
      css: {
        [`${SwitchBox}`]: {
          borderColor: setColorTheme('primary200'),
          backgroundColor: setColorTheme('primary200'),
        },
      },
    },
  ],
});

export const HiddenCheckbox = styled('input', {
  border: '0',
  position: 'absolute',
  width: '1px',
  opacity: '0',
  zIndex: '-1',
  cursor: 'default',
});

export const ErrorLabel = styled(Label, {
  width: '100%',
});
