import { gql } from '@apollo/client';

export const insertService = gql`
  mutation assignService(
    $clientId: uuid!
    $serviceId: uuid!
    $componentsId: [uuid]
  ) {
    insert_clients_services_one(
      object: { client_id: $clientId, service_id: $serviceId }
    ) {
      id
      service {
        id
        name
        clients_services_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const deleteClientComponentsService = gql`
  mutation deleteClientComponentsService($serviceId: uuid!) {
    delete_clients_components_services(
      where: { service_id: { _eq: $serviceId } }
    ) {
      affected_rows
    }
  }
`;

export const insertComponentService = gql`
  mutation insertClientsComponentsServices(
    $componentsServices: [clients_components_services_insert_input!]!
  ) {
    insert_clients_components_services(objects: $componentsServices) {
      affected_rows
      returning {
        service_id
        component_id
      }
    }
  }
`;

export const removeService = gql`
  mutation deleteService($id: uuid!) {
    delete_clients_services(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
