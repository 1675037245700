import {
  CardBase,
  FlexContainer,
  GridContainer,
  Input,
  Label,
  Modal,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const LicenseIdApiKeyStyles = styled(CardBase)``;

export const LicenseIdApiKeyInput = styled(Input)`
  width: 36rem;
  margin-bottom: 2rem;
`;

export const ModalApiKeyStyles = styled(Modal)`
  width: 88rem;
  min-height: 60rem;
`;

export const ModalApiKeyInputRow = styled(FlexContainer)<{
  hasMargin?: boolean;
}>`
  margin-bottom: ${(props) => (props.hasMargin ? '2rem' : 0)};
  > * {
    width: 36.8rem;
  }
`;

export const ModalApiKeyMessage = styled(Label)`
  margin: 1.6rem 0 2rem;
`;

export const LicenseIdGrid = styled(GridContainer)`
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3.5fr 1fr;

  > * {
    height: 100%;
    overflow-y: auto;
    grid-row: 1/1;
  }

  > *:nth-child(1) {
    grid-column: 1/3;
  }

  > *:nth-child(2) {
    grid-row: 1/3;
  }

  ${LicenseIdApiKeyStyles} {
    grid-row: 2/2;
    grid-column: 1/3;
  }
`;
