import { useQuery } from '@apollo/client';
import { Service } from '@cs/state/model';
import { getServiceByLicense } from '@cs/state/queries';
import { CardBase, CardBaseHeader, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LicenseIdCardList, LicenseIdElement } from './Styles';

type LicenseServiceDto = {
  licenses: [
    {
      clients_service: {
        service: Service;
      };
    },
  ];
};

export const LicenseIdCardRight = () => {
  const { t } = useTranslation();
  const routeParams = useParams();

  const { data } = useQuery<LicenseServiceDto>(getServiceByLicense, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: routeParams.licenseId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <CardBase flex="1" as="section">
      <CardBaseHeader>
        <Label fontSize="14" semibold>
          {t('Service list')}
        </Label>
      </CardBaseHeader>

      <LicenseIdCardList flexDirection="column" forwardedAs="ul">
        {data?.licenses &&
          data?.licenses.map((item) => (
            <LicenseIdElement
              key={item.clients_service.service.id}
              forwardedAs="li"
              justifyContent="space-between"
              alignItems="center"
              columnGap="1.6"
            >
              <Label fontSize="14" semibold>
                {item.clients_service.service.name}
              </Label>
            </LicenseIdElement>
          ))}
      </LicenseIdCardList>
    </CardBase>
  );
};
