import { useState } from 'react';
import { ModalLogOut } from '@cs/components';
import { UserDto } from '@cs/state/model';
import {
  Button,
  CardBaseFooter,
  FlexContainer,
  Label,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  ProfileCard,
  ProfileCardContent,
  ProfileCardContentSection,
  ProfileCardHeader,
  ProfileCardInput,
} from './Styles';

type Props = {
  user: UserDto;
};

export const ProfileForm = ({ user }: Props) => {
  const [showLogOut, setShowLogOut] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <ProfileCard flexDirection="column" flex="1">
        <ProfileCardHeader>
          <img src={user.avatar ? user.avatar : '/Avatar.png'} alt="user" />
        </ProfileCardHeader>

        <ProfileCardContent flexDirection="column" flex="1">
          <ProfileCardContentSection
            forwardedAs="section"
            rowGap="2"
            flexDirection="column"
          >
            <Label fontSize="16" semibold>
              {t('Personal information')}
            </Label>

            <FlexContainer columnGap="9">
              <ProfileCardInput
                testId="input-name"
                label={t('Name')}
                value={user.name}
                disabled
              />

              <ProfileCardInput
                testId="input-surname"
                label={t('Surname')}
                value={user.surname}
                disabled
              />
            </FlexContainer>
          </ProfileCardContentSection>

          <ProfileCardContentSection
            forwardedAs="section"
            rowGap="2"
            flexDirection="column"
          >
            <Label fontSize="16" semibold>
              {t('Account setting')}
            </Label>

            <FlexContainer columnGap="9">
              <ProfileCardInput
                testId="input-email"
                label={t('Email')}
                value={user.email}
                disabled
              />
            </FlexContainer>
          </ProfileCardContentSection>
        </ProfileCardContent>

        <CardBaseFooter columnGap="1.6" hasPadding>
          <Button
            iconName="SignOut"
            color="error"
            variant="outline"
            onClick={() => setShowLogOut(!showLogOut)}
          >
            {t('Logout')}
          </Button>
        </CardBaseFooter>
      </ProfileCard>

      <ModalLogOut
        show={showLogOut}
        onChangeShow={(showLogOut: boolean) => setShowLogOut(showLogOut)}
      />
    </>
  );
};
