import React, { createContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { Hasura } from '../state/constants';
import { Roles, UserDto } from '../state/model';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  userData: UserDto;
  isAuthenticated?: boolean;
  idToken: string | null | undefined;
  token: string | null | undefined;
  role: Roles | undefined;
  handleRefreshToken(): Promise<string | undefined>;
};

const Ctx = createContext<ContextProps>({
  userData: {} as UserDto,
  isAuthenticated: false,
  idToken: null,
  token: null,
  role: undefined,
  handleRefreshToken: () => new Promise((resolve) => resolve('')),
});

export const AuthService = ({ children }: IProps) => {
  const { isAuthenticated, user, signinSilent } = useAuth();
  const userData: UserDto = user?.profile as unknown as UserDto;

  const handleRefreshToken = (): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      signinSilent({ silentRequestTimeoutInSeconds: 5 })
        .then((resp) => {
          resolve(resp?.access_token);
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <Ctx.Provider
      value={{
        userData: {
          ...userData,
          name: user?.profile.given_name,
          surname: user?.profile.family_name,
        },
        isAuthenticated,
        idToken: user?.id_token,
        token: user?.access_token,
        role: userData?.[Hasura.claims]?.[
          Hasura.allowedRoles
        ][0].toUpperCase() as Roles,
        handleRefreshToken,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useAuthService = () => React.useContext(Ctx);
