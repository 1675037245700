import { gql } from '@apollo/client';
import { tenantFragment } from '../fragments';

export const insertTenant = gql`
  ${tenantFragment}
  mutation createTenant(
    $clientId: uuid!
    $name: String!
    $timezone: String!
    $address: String!
  ) {
    insert_tenants_one(
      object: {
        client_id: $clientId
        name: $name
        timezone: $timezone
        address: $address
      }
    ) {
      ...tenantsFields
      users_tenants {
        id
      }
    }
  }
`;

export const removeTenant = gql`
  mutation deleteTenant($id: uuid!) {
    update_tenants_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const undoRemoveTenant = gql`
  ${tenantFragment}
  mutation undoRemoveTenant($id: uuid!) {
    update_tenants_by_pk(pk_columns: { id: $id }, _set: { deleted_at: null }) {
      ...tenantsFields
      users_tenants {
        user {
          id
          name
        }
      }
    }
  }
`;

export const modifyTenant = gql`
  mutation editTenant($id: uuid!, $tenant: tenants_set_input!) {
    update_tenants_by_pk(pk_columns: { id: $id }, _set: $tenant) {
      id
      name
      address
      timezone
    }
  }
`;

export const removeTenantUsers = gql`
  mutation removeTenantUsers($tenantId: uuid, $usersId: [uuid!]) {
    delete_users_tenants(
      where: { tenant_id: { _eq: $tenantId }, user_id: { _in: $usersId } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const addTenantUsers = gql`
  mutation addTenantUsers($tenantId: uuid!, $usersId: [uuid!]!) {
    addTenantUsers(tenantId: $tenantId, users: $usersId) {
      id
      user {
        id
        email
      }
    }
  }
`;
