import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { MaxItemsPag } from '@cs/state/constants';
import { getTenants } from '@cs/state/queries';
import { usePaginate } from './usePaginate';

export function useTenantsPaginate(clientId: string) {
  const {
    search,
    changePage,
    offset,
    handleSearch,
    currentPage,
    getQueryVariables,
  } = usePaginate();

  const { loading, data, error, refetch } = useQuery(getTenants, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getQueryVariables(),
      clientId,
      offset: offset,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const tenants = useMemo(
    () => (data ? data.clients_by_pk.tenants : []),
    [data],
  );
  const total = useMemo(
    () => (data ? data.clients_by_pk.tenants_aggregate.aggregate.count : 0),
    [data],
  );

  const totalPages = useMemo(
    () => Math.ceil(total / MaxItemsPag),
    [offset, MaxItemsPag, total],
  );

  const updateTenants = () => refetch(getQueryVariables());

  return {
    data,
    tenants,
    handleSearch,
    total,
    currentPage,
    totalPages,
    changePage,
    variables: getQueryVariables(),
    updateTenants,
    tenantsError: error,
    search,
    loading,
  };
}
