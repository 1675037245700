import { gql } from '@apollo/client';

export const requestFragment = gql`
  fragment request on request {
    id
    bundleId
    active
    client {
      name
      id
    }
    comments
    components
    country
    issue
    new_client
    technology
    trackingServer
    origin
    apikey
    service {
      id
      name
    }
    created_at
  }
`;
