import { useCopyToClipboard } from '@cs/hooks';
import { NewLicenseDto } from '@cs/state/model';
import { ModalStateProps } from '@facephi/ui-react';
import { Icon, Button, Label, Chips, FlexContainer } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  ModalNewLicenseStyles,
  StyledModalContent,
  StyledLabelBold,
  StyledModalOverflow,
} from './Styles';

type Props = ModalStateProps & {
  license: NewLicenseDto;
};

export const ModalDetailLicense = ({ show, onChangeShow, license }: Props) => {
  const { t } = useTranslation();

  const { copy } = useCopyToClipboard();

  return (
    <ModalNewLicenseStyles
      show={show}
      testId="modal-detail-license"
      onChangeShow={onChangeShow}
      hasPadding
      overlayBlur
      header={{
        iconName: 'MagnifyingGlass',
        title: `${t('Detail License')}: ${license.name}`,
      }}
    >
      <StyledModalOverflow flexDirection="column">
        <StyledLabelBold size={12} upperCase textAlign="center">
          {t('Api Key')}
        </StyledLabelBold>
        <Button
          variant="text"
          type="button"
          semiBold={false}
          onClick={() => (license ? copy(license.apikey as string) : undefined)}
        >
          {license?.apikey}
          <Icon iconName="Copy" size="16" />
        </Button>
        {license.description && (
          <>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Description')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {license.description}
            </Label>
          </>
        )}
        <StyledLabelBold size={12} upperCase textAlign="center">
          {t('EndDate')}
        </StyledLabelBold>
        <Label size={14} textAlign="center">
          {license.endDate}
        </Label>
        {license.bundleId && (
          <>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Application Id / Bundle Id')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {license.bundleId}
            </Label>
          </>
        )}
        {license.origins && (
          <>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Origins')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              <FlexContainer alignItems="center" justifyContent="center">
                {license.origins.map((item) => (
                  <Chips key={item} label={item} />
                ))}
              </FlexContainer>
            </Label>
          </>
        )}
        <StyledLabelBold size={12} upperCase textAlign="center">
          {t('Service')}
        </StyledLabelBold>
        <Label size={14} textAlign="center">
          {license.service}
        </Label>
        <StyledLabelBold size={12} upperCase textAlign="center">
          {t('Components')}
        </StyledLabelBold>
        {Object.keys(license.license).map((item) => (
          <div key={item}>
            <Label size={10} upperCase textAlign="center">
              {item}
            </Label>
            <StyledModalContent
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {Object.keys(license.license[item]).map((i) => (
                <div key={i}>
                  {i}: {license.license[item][i]}
                </div>
              ))}
            </StyledModalContent>
          </div>
        ))}
      </StyledModalOverflow>
    </ModalNewLicenseStyles>
  );
};
