import { useMutation } from '@apollo/client';
import { listUsersDto } from '@cs/state/model';
import { modifyUser } from '@cs/state/mutations';
import { listUsers } from '@cs/state/queries';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBlockUser(variables?: any) {
  const [blockUser] = useMutation(modifyUser);
  const { toastManager } = useToast();
  const { t } = useTranslation();

  const onHandleBlockUser = async (id: string, active: boolean) => {
    await blockUser({
      variables: {
        id: id,
        user: {
          active: !active,
        },
      },
      refetchQueries: [{ query: listUsers }],
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t(active ? 'User block' : 'User unblock'),
          testId: 'toast-block-user',
        });
      },
      update: (cache) => {
        if (variables) {
          const response = cache.readQuery<listUsersDto>({
            query: listUsers,
            variables: variables(),
          });

          const usersUpdate = {
            ...response,
            clients_by_pk: {
              users: response?.clients_by_pk.users.map((item) =>
                item.id === id ? { ...item, active: !active } : item,
              ),
            },
          };

          cache.writeQuery({
            query: listUsers,
            variables: variables(),
            data: usersUpdate,
          });
        }
      },
    });
  };

  return {
    onHandleBlockUser,
  };
}
