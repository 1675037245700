import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RoutesName } from '@cs/state/constants';
import { User } from '@cs/state/model';
import { addTenantUsers, removeTenantUsers } from '@cs/state/mutations';
import { getUsersByClient, getUsersByTenant } from '@cs/state/queries';
import {
  CardBase,
  CardBaseHeader,
  DropdownSearch,
  DropdownSearchOption,
  FlexContainer,
  IconWrapper,
  Label,
  useToast,
} from '@facephi/ui-react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';
import { CardRigthUserIdContent, CardTenantAsigned } from '../userId';
import { CardEmptyState } from './Styles';

type listUsers = {
  users_tenants: {
    id: string;
    user: User;
  }[];
};

type Props = { tenantId: string; clientId: string };

export const CardRightTenantId = ({ tenantId, clientId }: Props) => {
  const { t, i18n } = useTranslation();
  const { toastManager } = useToast();
  const navigate = useNavigate();

  const { data: usersByTenant } = useQuery<listUsers>(getUsersByTenant, {
    variables: {
      tenantId,
    },
  });

  const usersTenant = useMemo(
    () =>
      usersByTenant
        ? usersByTenant?.users_tenants.filter(({ user }) => user !== null)
        : [],
    [usersByTenant],
  );

  const { data: usersByClient } = useQuery<{ users: User[] }>(
    getUsersByClient,
    {
      variables: {
        clientId,
      },
    },
  );

  const [deleteUsers] = useMutation(removeTenantUsers);
  const [insertUsers] = useMutation(addTenantUsers);

  const options: DropdownSearchOption[] = useMemo(() => {
    return (
      usersByClient?.users.map(({ id, email }) => ({
        name: email,
        value: id,
      })) || []
    );
  }, [usersByClient]);

  const addUsers = (usersId: string[]) => {
    return new Promise((resolve, reject) => {
      if (!usersId.length) return resolve(false);
      insertUsers({
        variables: {
          tenantId,
          usersId,
        },
        refetchQueries: ['getTenantsByUser'],
        update: (cache, { data: { addTenantUsers } }) => {
          const response = cache.readQuery<listUsers>({
            query: getUsersByTenant,
            variables: {
              tenantId,
            },
          });

          if (response && addTenantUsers) {
            const queryData = cloneDeep(response);
            queryData.users_tenants.push(...addTenantUsers);

            cache.writeQuery({
              query: getUsersByTenant,
              variables: {
                tenantId,
              },
              data: queryData,
            });
          }
        },
      })
        .catch(() => reject())
        .then(() => resolve(true));
    });
  };

  const removeUser = (usersId: string[], notify = true) => {
    return new Promise((resolve, reject) => {
      if (!usersId.length) return resolve(false);
      deleteUsers({
        variables: { tenantId, usersId },
        update: (cache, { data: { delete_users_tenants } }) => {
          const response = cache.readQuery<listUsers>({
            query: getUsersByTenant,
            variables: {
              tenantId,
            },
          });
          const queryData = cloneDeep(response);

          const usersToDelete = delete_users_tenants.returning.map(
            ({ id }: { id: string }) => id,
          );

          if (queryData && usersToDelete) {
            queryData.users_tenants = queryData.users_tenants.filter(
              ({ id }: { id: string }) => !usersToDelete.includes(id),
            );

            cache.writeQuery({
              query: getUsersByTenant,
              data: queryData,
              variables: {
                tenantId,
              },
            });
          }
        },
        onCompleted: () => {
          notify &&
            toastManager({
              type: 'success',
              message: t('User deleted'),
              testId: 'toast-delete-tenant',
            });
        },
      })
        .catch(() => reject())
        .then(() => resolve(true));
    });
  };

  const handleUsers = async (usersId: string[]) => {
    const toAdd = usersId.filter(
      (id) => !usersTenant?.find(({ user }) => user.id === id),
    );
    const toRemove =
      usersTenant?.filter(({ user }) => !usersId.includes(user.id)) || [];

    try {
      const response = await Promise.all([
        addUsers(toAdd),
        removeUser(
          toRemove?.map(({ user }) => user.id),
          false,
        ),
      ]);

      if (response.some((result) => result)) {
        toastManager({
          type: 'success',
          message: t('Users updated'),
          testId: 'toast-delete-tenant',
        });
      }
    } catch {
      toastManager({
        type: 'error',
        message: t('Something went wrong, try again'),
        testId: 'toast-delete-tenant',
      });
    }
  };

  const { emptyState } = useEmptyState({
    emptyUsers: !usersTenant?.length,
  });

  return (
    <CardBase flexDirection="column" flex="1">
      <CardBaseHeader>
        <FlexContainer columnGap="0.8" alignItems="center">
          <IconWrapper iconName="UserCircle" color="pink" size="M" />
          <Label fontSize="14" semibold>
            {t('Users')}
          </Label>
        </FlexContainer>
        <FlexContainer columnGap="0.4">
          <Label fontSize="14">{`${t('Total assigned')}:`}</Label>

          <Label fontSize="14" semibold color="cyan400">
            {usersTenant?.length || 0}
          </Label>
        </FlexContainer>
      </CardBaseHeader>

      <CardRigthUserIdContent flexDirection="column" flex="1">
        <DropdownSearch
          value={usersTenant.map(({ user }) => user?.id) || []}
          options={options}
          multiple
          locale={i18n.language}
          showListContent={false}
          placeholder={t('Select user and assign to this tenant')}
          onChange={() => {}}
          onClose={handleUsers}
        />
        {usersTenant.map(({ user }: { user: User }) => (
          <CardTenantAsigned
            key={user.id}
            label={user.email}
            onDelete={() => removeUser([user.id])}
            onEdit={() => {
              navigate(
                generatePath(RoutesName.userId, {
                  clientId: clientId,
                  userId: user.id,
                }),
              );
            }}
          />
        ))}
        {!usersTenant?.length && (
          <CardEmptyState
            image={emptyState?.image}
            title={emptyState?.title || ''}
            subtitle={emptyState?.subtitle}
          />
        )}
      </CardRigthUserIdContent>
    </CardBase>
  );
};
