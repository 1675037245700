import { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuthService } from '@cs/providers';
import { cache } from '@cs/state/cache';
import { useAuth } from 'react-oidc-context';

type IProps = {
  children: ReactNode;
};

export function ApiProvider({ children }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiUrl: string = (window as any)._env_.REACT_APP_API_URL;
  const { removeUser } = useAuth();
  const { token, handleRefreshToken, role } = useAuthService();

  const httpLink = createHttpLink({
    uri: `${apiUrl}graphql`,
    credentials: 'same-origin',
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'x-hasura-role': role?.toLowerCase(),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      graphQLErrors.map(({ extensions }: any) => {
        const statusCode = extensions?.exception?.response?.statusCode;

        if (statusCode === 401) {
          return fromPromise(
            handleRefreshToken()
              .catch(() => {
                removeUser();
              })
              .then((updatedToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${updatedToken}`,
                  },
                });
              }),
          );
        }

        return false;
      });
  });

  const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

  const client = new ApolloClient({
    link,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
