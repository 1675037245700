import { gql } from '@apollo/client';

export const getProfile = gql`
  query getProfile($id: uuid!) {
    profiles_by_pk(id: $id) {
      avatar
      email
      name
      given_name
      family_name
      id
    }
  }
`;
