/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import validator from '@rjsf/validator-ajv8';

import { customTemplates } from './CustomTemplates';
import { customWidgets } from './CustomWidgets';
import { FormStyled } from './Styles';

type IProps = {
  schema: any;
  value: any;
  onChange(value: any): void;
  onError?(errors: any): void;
};

export const FormComponent = ({ schema, value, onChange, onError }: IProps) => {
  const [formData, setFormData] = useState(value);

  const onChangeHandle = ({ formData, errors }: any) => {
    setFormData(formData);
    if (errors.length && onError) {
      onError(errors);
    }
  };

  useEffect(() => {
    onChange(formData);
  }, [formData]);

  return (
    <FormStyled
      schema={schema}
      formData={formData}
      widgets={customWidgets}
      validator={validator}
      templates={customTemplates}
      liveValidate
      onChange={onChangeHandle}
    >
      <></>
    </FormStyled>
  );
};
