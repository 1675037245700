export class AdditionalPropertiesDto {
  completedWith: string;
}

export enum FormCompletedWith {
  service = 'service',
  endDate = 'endDate',
  bundleId = 'bundleId',
  clientName = 'client.name',
}
