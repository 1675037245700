import { Dropdown, DropdownOption } from '@facephi/ui-react';
import { WidgetProps } from '@rjsf/utils';
import { useTranslation } from 'react-i18next';

export type OptionsMenu = {
  label: string;
  value: string;
};

export const DropdownCustom = ({
  value,
  onChange,
  label,
  options,
  required,
  id,
}: WidgetProps) => {
  const { t } = useTranslation();

  const customOptions: DropdownOption[] =
    options?.enumOptions?.map((item: OptionsMenu) => ({
      name: t(item.label),
      value: item.value,
    })) || [];

  return (
    <Dropdown
      id={id}
      value={value}
      onChange={onChange}
      label={t(label) + `${required ? ' *' : ''}`}
      options={customOptions}
      testIdContainer={id}
      canRemoveValue
    />
  );
};
