import {
  Button,
  FlexContainer,
  Label,
  Modal,
  setColorTheme,
} from '@facephi/ui-react';
import Form from '@rjsf/core';
import styled from 'styled-components/macro';

export const LicenseWrapper = styled(FlexContainer)`
  background-color: ${setColorTheme('white')};
  height: 100%;
  border-radius: 0.8rem;
`;

export const ModalNewLicenseStyles = styled(Modal)`
  width: 90rem;
  margin: 2rem 0;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > form {
    overflow: auto;
    flex: 1;
  }

  > div:last-child {
    align-self: flex-end;
    width: 100%;
  }
`;

export const FieldStyled = styled(FlexContainer)`
  height: 100%;
`;

export const FormStyled = styled(Form)`
  margin-top: 1rem;
  fieldset {
    flex-direction: column;
    > div {
      margin-bottom: 1rem;
    }
  }

  #root__title {
    display: none;
  }
`;

export const FieldComponent = styled.div`
  margin-bottom: 3rem;

  > div {
    margin-top: 1rem;
  }
`;

export const StyledModalContent = styled(FlexContainer)`
  margin: 2rem;
`;

export const StyledModalOverflow = styled(FlexContainer)`
  overflow: auto;
`;

export const StyledLabelBold = styled(Label)`
  font-weight: 800;
  margin-top: 2rem;
`;

export const StyledContainerOrigins = styled(FlexContainer)`
  width: 100%;
`;

export const StyledMultipleOrigins = styled(FlexContainer)`
  white-space: nowrap;
  padding-left: 0.6rem;
`;

export const StyledTableButton = styled(Button)`
  padding: 0;
`;
