import {
  CardBaseHeader,
  CardCollapsable,
  FlexContainer,
  InputUpload,
  setColorTheme,
  setShadowTheme,
  Label,
  Switch,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const UsersIdStyles = styled(FlexContainer)`
  height: 100%;
  margin-top: 2rem;
`;

export const CardRigthUserIdContent = styled(FlexContainer)`
  padding: 1.8rem 3.2rem;

  > * {
    width: 100%;
  }
  > *:first-of-type {
    margin-bottom: 0.8rem;
  }
`;

export const CardTenantAsignedStyles = styled(FlexContainer)`
  background-color: ${setColorTheme('white')};
  box-shadow: ${setShadowTheme('medium')};
  height: 4.8rem;
  padding: 0 1.6rem;
  border-radius: 0.8rem;
  margin-top: 0.8rem;
`;

export const CardLeftUserIdElements = styled(FlexContainer)`
  > * {
    flex: 1;
  }
`;

export const CardLeftUserIdHeader = styled(CardBaseHeader)`
  position: relative;
`;

export const UserIdInputUpload = styled(InputUpload)`
  position: relative;
  top: calc(-6.6rem / 2);
  left: 4.2rem;
`;

export const CardCollapsableRoleStyles = styled(CardCollapsable)`
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const MiniLabel = styled(Label)`
  margin: 2rem 1rem;
`;

export const FieldSeparator = styled.div<{ show: boolean }>`
  margin: 0 1rem;
  display: ${(props) => (props.show ? 'auto' : 'none')};

  > div {
    margin-bottom: 1rem;
  }

  > button {
    margin-bottom: 1rem;
  }
`;

export const CustomSwitch = styled(Switch)`
  margin: 2rem 1rem;
`;
