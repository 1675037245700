import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Client } from '@cs/state/model';
import { getAllClients } from '@cs/state/queries';

export function useClients() {
  const { data } = useQuery(getAllClients);

  const optionClients = useMemo(
    () =>
      data
        ? data.clients.map((item: Client) => ({
            name: item.name,
            value: item.id,
            label: item.name,
          }))
        : [],
    [data],
  );

  return {
    optionClients,
  };
}
