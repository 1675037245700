import { gql } from '@apollo/client';

export const getLanguages = gql`
  query getLanguages {
    languages {
      code
      name
    }
  }
`;
