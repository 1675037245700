import { gql } from '@apollo/client';

export const insertRequest = gql`
  mutation insertRequest($request: RequestInput!) {
    request: insertRequest(request: $request) {
      apikey
      issue
    }
  }
`;

export const insertCommentRequest = gql`
  mutation insertCommentRequest($requestId: uuid!, $comment: String!) {
    requestComment(requestId: $requestId, comment: $comment) {
      status
    }
  }
`;

export const deleteRequest = gql`
  mutation deleteRequest($id: uuid!) {
    update_request_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const undoDeleteRequest = gql`
  mutation undoDeleteRequest($id: uuid!) {
    update_request_by_pk(pk_columns: { id: $id }, _set: { deleted_at: null }) {
      id
    }
  }
`;

export const activateRequest = gql`
  mutation activeRequest($requestId: uuid!) {
    activateRequest(requestId: $requestId) {
      active
    }
  }
`;
