import { gql } from '@apollo/client';

export const getRoles = gql`
  query getRoles($clientId: uuid!, $tenantName: String!) {
    roles: getRoles(clientId: $clientId, tenantName: $tenantName) {
      name
      id
    }
  }
`;

export const getUserRole = gql`
  query getUserRole($tenantId: uuid!, $userId: uuid!) {
    role: users_role(
      where: { tenant_id: { _eq: $tenantId }, user_id: { _eq: $userId } }
    ) {
      role
      role_id
    }
  }
`;
