import { gql } from '@apollo/client';

export const listClients = gql`
  query listClients($name: String, $offset: Int, $limit: Int) {
    clients(
      where: { name: { _ilike: $name } }
      offset: $offset
      limit: $limit
      order_by: { name: asc }
    ) {
      created_at
      avatar
      active
      name
      description
      id
      slug
      platform
      country
      licenses_aggregate {
        aggregate {
          count
        }
      }
      tenants_aggregate {
        aggregate {
          count
        }
      }
      users_aggregate: users_clients_aggregate {
        aggregate {
          count
        }
      }
    }
    clients_aggregate(where: { name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
  }
`;

export const getAllClients = gql`
  query listAllClients {
    clients {
      id
      name
    }
  }
`;

export const getClient = gql`
  query getClient($id: uuid!) {
    clients_by_pk(id: $id) {
      name
      slug
      description
      id
      email
      country
      avatar
      platform
    }
  }
`;
