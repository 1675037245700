import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CardLeftUserIdElements,
  CardLeftUserIdHeader,
  CustomSwitch,
  ProfileCardContent,
  UserIdInputUpload,
} from '@cs/components';
import { useApi, useBlockUser, useLanguage, useTimezone } from '@cs/hooks';
import {
  AcceptedFiles,
  ANTIFRAUD,
  ENABLE_VIDEO,
  LANGUAGE,
  TIMEZONE,
  LIVENESS,
  MaxSizeImage,
  SECURITY,
  SECURITY_CONFIG,
  SELPHI_SDK,
  AUTOMATIC_SCAN,
  ENABLE_AUTOMATIC_SCAN,
  ADVANCED_TRACKING,
  TIMEOUT_IMAGES,
} from '@cs/state/constants';
import { editUserSchema, UserPersonalInformationDto } from '@cs/state/model';
import { modifyUser } from '@cs/state/mutations';
import {
  Button,
  CardBase,
  CardBaseFooter,
  Checkbox,
  Dropdown,
  DropdownSearch,
  FlexContainer,
  Input,
  Label,
  Spinner,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SecurityOptions } from './SecurityOptions';

export const CardLeftUserId = ({
  user,
  clientId,
}: {
  user: UserPersonalInformationDto;
  clientId: string;
}) => {
  const { t } = useTranslation();
  const { toastManager } = useToast();
  const { uploadFile, getFile } = useApi();

  const [avatar, setAvatar] = useState<Blob>();
  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const { optionsTimeZone } = useTimezone();
  const { optionsLanguage } = useLanguage();

  const [innerActive, setInnerActive] = useState<boolean>(
    user.active as boolean,
  );

  const [editUser] = useMutation(modifyUser);

  const { onHandleBlockUser } = useBlockUser();

  const getAvatar: () => void = async () => {
    if (user.avatar) {
      try {
        const imageBlob = await getFile(user.avatar);
        setAvatar(imageBlob);
      } catch (e) {
        toastManager({
          type: 'error',
          message: t('Something went wrong downloading the avatar'),
          testId: 'toast-modify-tenant',
        });
      }
    }
  };

  useEffect(() => {
    getAvatar();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(editUserSchema),
    mode: 'onChange',
    defaultValues: {
      name: user?.name,
      email: user?.email,
      lastName: user?.lastName,
      firstName: user?.firstName,
      language: user?.language,
      timezone: user?.timezone,
      ...user?.metadata,
      [TIMEOUT_IMAGES]: user.metadata?.timeout_images,
      [SECURITY]: user.metadata?.security || true,
      [SECURITY_CONFIG]: user.metadata?.securityConfig || {
        auto: {
          enabled: true,
          side: 'FRONT',
        },
        manual: {
          enabled: true,
          provider: 'veriff',
          side: 'FRONT+BACK',
          environment: 'development',
        },
      },
    },
  });

  const blockUser = () => {
    onHandleBlockUser(user.id, innerActive);
    setInnerActive(false);
  };

  const activeUser = () => {
    onHandleBlockUser(user.id, innerActive);
    setInnerActive(true);
  };

  const handleImage = (img: File) => setImage(img);

  const onSubmit = async (values: FieldValues) => {
    setLoading(true);
    try {
      let fileId;
      if (image) {
        ({ id: fileId } = await uploadFile({
          clientId,
          file: image,
        }));
      }
      await editUser({
        variables: {
          id: user.id,
          user: {
            name: values.name,
            email: values.email,
            avatar: fileId,
            active: innerActive,
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            timezone: values.timezone,
            language: values.language,
            metadata: {
              [ADVANCED_TRACKING]: values.advanced_tracking,
              [ENABLE_VIDEO]: values.enable_video,
              [SECURITY]: values.security,
              [ANTIFRAUD]: values.antifraud,
              [LIVENESS]: values.liveness,
              [SECURITY_CONFIG]: values.securityConfig,
              [TIMEOUT_IMAGES]: values[TIMEOUT_IMAGES],
              [SELPHI_SDK]: values[SELPHI_SDK] ? values[SELPHI_SDK] : undefined,
              [ENABLE_AUTOMATIC_SCAN]: values[ENABLE_AUTOMATIC_SCAN],
              [AUTOMATIC_SCAN]: !values[ENABLE_AUTOMATIC_SCAN]
                ? false
                : values[AUTOMATIC_SCAN],
            },
          },
        },
        onCompleted: () => {
          toastManager({
            type: 'success',
            message: t('User modified successfully'),
            testId: 'toast-modify-user',
          });
        },
      });
    } catch {
      toastManager({
        type: 'error',
        message: t('Somenthing went wrong, try again'),
        testId: 'toast-modify-error',
      });
    } finally {
      setLoading(false);
    }
  };

  const security = useWatch({ name: SECURITY, control });
  const enabledAutomaticScan = useWatch({
    name: ENABLE_AUTOMATIC_SCAN,
    control,
  });

  return (
    <CardBase flexDirection="column" as="form" flex="2">
      {loading && <Spinner />}
      <CardLeftUserIdHeader>
        <UserIdInputUpload
          value={avatar}
          acceptFiles={AcceptedFiles}
          maxSize={MaxSizeImage}
          onChange={handleImage}
        />

        {!innerActive && (
          <Button
            color="success"
            variant={innerActive ? 'filled' : 'outline'}
            onClick={() => activeUser()}
            iconName="CheckCircle"
            testId="active-button"
          >
            {t('Active')}
          </Button>
        )}

        {innerActive && (
          <Button
            color="error"
            variant={!innerActive ? 'filled' : 'outline'}
            onClick={() => blockUser()}
            iconName="Prohibit"
            testId="block-button"
          >
            {t('Block')}
          </Button>
        )}
      </CardLeftUserIdHeader>

      <ProfileCardContent flexDirection="column" rowGap="6" flex="1">
        <FlexContainer as="section" rowGap="2" flexDirection="column">
          <Label fontSize="16" semibold>
            {t('Personal information')}
          </Label>

          <CardLeftUserIdElements columnGap="7">
            <Input
              {...register('name')}
              testId="input-name"
              label={t('Username')}
              errorLabel={errors.name?.message && t(errors.name.message)}
            />
            <Input
              value={user.id}
              testId="input-userId"
              label={t('User ID')}
              disabled
            />
          </CardLeftUserIdElements>
        </FlexContainer>

        <CardLeftUserIdElements columnGap="7">
          <Input
            {...register('email')}
            testId="input-email"
            label={t('Email')}
            errorLabel={errors.email?.message && t(errors.email.message)}
          />

          <Input
            {...register('password')}
            testId="input-password"
            label={t('New Password')}
            type="password"
            errorLabel={errors.password?.message && t(errors.password.message)}
          />
        </CardLeftUserIdElements>

        <CardLeftUserIdElements columnGap="7">
          <Input
            {...register('firstName')}
            testId="input-firstname"
            label={t('First Name')}
            errorLabel={
              errors.firstName?.message && t(errors.firstName.message)
            }
          />
          <Input
            {...register('lastName')}
            label={t('Last Name')}
            testId="input-lastName"
            errorLabel={errors.lastName?.message && t(errors.lastName.message)}
          />
        </CardLeftUserIdElements>

        <CardLeftUserIdElements columnGap="7">
          <Controller
            name={LANGUAGE}
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={({ field }: any) => (
              <Dropdown
                {...field}
                options={optionsLanguage}
                label={t('Language')}
                placeholder={t('Select language')}
                name={LANGUAGE}
              />
            )}
          />

          <Controller
            name={TIMEZONE}
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={({ field }: any) => (
              <DropdownSearch
                {...field}
                options={optionsTimeZone}
                placeholder={t('Select timezone')}
                label={t('Timezone')}
                name={TIMEZONE}
              />
            )}
          />
        </CardLeftUserIdElements>
      </ProfileCardContent>

      <ProfileCardContent flexDirection="column" rowGap="6" flex="1">
        <FlexContainer as="section" rowGap="2" flexDirection="column">
          <Label fontSize="16" semibold>
            {t('User data in the demos')}
          </Label>
        </FlexContainer>

        <CardLeftUserIdElements columnGap="7">
          <Controller
            control={control}
            name={ENABLE_VIDEO}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={value}
                checked={value}
                ref={ref}
              >
                {t('Enable Video')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name={LIVENESS}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={value}
                checked={value}
                ref={ref}
                testId="check-liveness"
              >
                {t('Enable liveness')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name={ANTIFRAUD}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={value}
                checked={value}
                ref={ref}
                testId="check-antifraud"
              >
                {t('Enable Antifraud')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name={ADVANCED_TRACKING}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={value}
                checked={value}
                ref={ref}
                testId="check-advanced-tracking"
              >
                {t('Enable Advanced tracking')}
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name={TIMEOUT_IMAGES}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={value}
                checked={value}
                ref={ref}
                testId="check-timeout-images"
              >
                {t('Enable Timeout Images')}
              </Checkbox>
            )}
          />
        </CardLeftUserIdElements>

        <CardLeftUserIdElements columnGap="7">
          <Input
            label={t('Sdk Version')}
            {...register(SELPHI_SDK)}
            placeholder={t('For example 6.6.0')}
          />
        </CardLeftUserIdElements>

        <CardLeftUserIdElements columnGap="7" flexDirection="column">
          <FlexContainer
            flexDirection="row"
            rowGap="30"
            justifyContent="center"
            alignItems="center"
          >
            <Label fontSize="12" semibold style={{ flex: 1 }}>
              {t('Security Configuration')}
            </Label>

            <Controller
              control={control}
              name={SECURITY}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSwitch
                  onChange={onChange}
                  name={name}
                  checked={value}
                  labelLeft={t('Enabled')}
                  ref={ref}
                  testId="check-security"
                />
              )}
            />
          </FlexContainer>

          {security && (
            <Controller
              name={SECURITY_CONFIG}
              control={control}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              render={({ field }: any) => <SecurityOptions {...field} />}
            />
          )}
        </CardLeftUserIdElements>

        <CardLeftUserIdElements>
          <Controller
            control={control}
            name={ENABLE_AUTOMATIC_SCAN}
            render={({ field: { onChange, value, name, ref } }) => (
              <CustomSwitch
                onChange={onChange}
                name={name}
                checked={value}
                labelLeft={t('Enable Automatic Scan')}
                ref={ref}
                testId="check-enable-automatic-scan"
              />
            )}
          />
          <Controller
            control={control}
            name={AUTOMATIC_SCAN}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                onChange={onChange}
                name={name}
                value={!enabledAutomaticScan ? false : value}
                checked={!enabledAutomaticScan ? false : value}
                ref={ref}
                disabled={!enabledAutomaticScan}
                testId="check-automatic-scan"
              >
                {t('Automatic Scan')}
              </Checkbox>
            )}
          />
        </CardLeftUserIdElements>
      </ProfileCardContent>

      <CardBaseFooter as="section" hasPadding>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          testId="button-modify-user"
          size="L"
        >
          {t('Save')}
        </Button>
      </CardBaseFooter>
    </CardBase>
  );
};
