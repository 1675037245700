import { Suspense, useEffect } from 'react';
import { timeZoneVar } from '@cs/state/cache';
import {
  createTheme,
  InphiniteThemeProvider,
  Spinner,
  TimezoneProvider,
  ToastProvider,
} from '@facephi/ui-react';
import { AuthProvider } from 'react-oidc-context';
import oidcConfig from './keycloak';
import { ApiProvider, AuthService, TranslationProvider } from './providers';
import { Routes } from './routes/Routes';
import { GlobalStyles } from './styles/GlobalStyles';

export const App = () => {
  const centralServicesTheme = createTheme('central-services-theme', {
    colors: {
      primary100: '#C0E6EF',
      primary200: '#80CDDF',
      primary300: '#40B4CF',
      primary400: '#009ABF',
      primary500: '#00748F',
      primary600: '#004D60',
      primary700: '#001E26',
      secondary100: '#C0E6EF',
      secondary200: '#80CDDF',
      secondary300: '#40B4CF',
      secondary400: '#009ABF',
      secondary500: '#00748F',
      secondary600: '#004D60',
      secondary700: '#001E26',
    },
  });

  useEffect(() => {
    document.body.className = centralServicesTheme;
  }, [centralServicesTheme]);

  return (
    <InphiniteThemeProvider>
      <Suspense fallback={<Spinner />}>
        <TimezoneProvider setTimezone={timeZoneVar} timezone={timeZoneVar()}>
          <ToastProvider>
            <GlobalStyles />
            <TranslationProvider>
              <AuthProvider {...oidcConfig}>
                <AuthService>
                  <ApiProvider>
                    <Routes />
                  </ApiProvider>
                </AuthService>
              </AuthProvider>
            </TranslationProvider>
          </ToastProvider>
        </TimezoneProvider>
      </Suspense>
    </InphiniteThemeProvider>
  );
};
