import * as yup from 'yup';
import YupPassword from 'yup-password';
import {
  BUNDLEID_VALIDATION,
  DESCRIPTION,
  EMAIL,
  EMAIL_INVALID_VALIDATION,
  EMAIL_VALIDATION,
  ENDDATE,
  END_AFTER_START_VALIDATION,
  END_DATE_VALIDATION,
  FINGERPRINT_VALIDATION,
  ID,
  SLUG,
  LICENSE,
  LICENSETYPE,
  COMPONENTS,
  NAME,
  NAME_VALIDATION,
  PASSWORD,
  REQUIRED_VALIDATION,
  SLUG_VALIDATION,
  SURNAME,
  USERNAME,
  FIRSTNAME,
  LASTNAME,
  ENABLE_VIDEO,
  ANTIFRAUD,
  JUMIO_ENVIRONMENT,
  SECURITY,
  SECURITY_PROVIDER,
  LIVENESS,
  SECURITY_CONFIG,
  PLATFORM,
  SERVICE,
  BUNDLE_ID,
  ORIGINS,
  SERVICE_NAME,
  FORMAT_VALIDATION,
  URL_EXP,
  SELPHI_SDK,
  COUNTRY,
} from '../constants';
import { RestrictionsApiKey } from './apiKey';
import { LicensesTypes } from './licenses';
import { LicenseService } from './services';
import { SecurityProvider } from './users';

export const loginSchema = yup.object().shape({
  [USERNAME]: yup.string().required(REQUIRED_VALIDATION),
  [PASSWORD]: yup.string().required(REQUIRED_VALIDATION),
});

YupPassword(yup);

export const addUserSchema = yup.object().shape({
  [EMAIL]: yup
    .string()
    .email(EMAIL_INVALID_VALIDATION)
    .required(EMAIL_VALIDATION),
});

export const createUserSchema = yup.object().shape({
  [EMAIL]: yup
    .string()
    .email(EMAIL_INVALID_VALIDATION)
    .required(EMAIL_VALIDATION),
  [FIRSTNAME]: yup.string().required(),
  [LASTNAME]: yup.string().required(),
  [PASSWORD]: yup
    .string()
    .required()
    .min(
      8,
      'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
    )
    .minSymbols(1, 'password must contain at least one symbol')
    .minLowercase(1, 'password must contain at least one lower case letter')
    .minUppercase(1, 'password must contain at least one upper case letter'),
  metadata: yup.object({
    [ENABLE_VIDEO]: yup.boolean().default(false),
    [ANTIFRAUD]: yup.boolean().default(true),
    [JUMIO_ENVIRONMENT]: yup.string().default('Development'),
    [SECURITY]: yup.boolean().default(true),
    [SECURITY_PROVIDER]: yup.string().default(SecurityProvider.veriff),
    [LIVENESS]: yup.boolean().default(false),
    [SECURITY_CONFIG]: yup.object(),
    [SELPHI_SDK]: yup
      .string()
      .matches(/^\d+\.\d+\.\d+$/, 'Is not in correct format'),
  }),
});

export const editUserSchema = yup.object().shape(
  {
    [EMAIL]: yup
      .string()
      .email(EMAIL_INVALID_VALIDATION)
      .required(EMAIL_VALIDATION),
    [FIRSTNAME]: yup.string().required(),
    [LASTNAME]: yup.string().required(),
    [PASSWORD]: yup
      .string()
      .nullable()
      .notRequired()
      .when(PASSWORD, {
        is: (value: string) => value?.length,
        then: (rule) =>
          rule
            .min(8)
            .minSymbols(1, 'password must contain at least one symbol')
            .minLowercase(
              1,
              'password must contain at least one lower case letter',
            )
            .minUppercase(
              1,
              'password must contain at least one upper case letter',
            ),
      }),
  },
  [[PASSWORD, PASSWORD]],
);

export const editClientSchema = yup.object().shape({
  [NAME]: yup.string().required(REQUIRED_VALIDATION),
  [DESCRIPTION]: yup.string(),
  [EMAIL]: yup.string().email(EMAIL_INVALID_VALIDATION).nullable(),
  [COUNTRY]: yup.string(),
});

export const createTenantSchema = yup.object().shape({
  [NAME]: yup.string().required(REQUIRED_VALIDATION),
});

export const profileSchema = yup.object().shape({
  [NAME]: yup.string().required(REQUIRED_VALIDATION),
  [SURNAME]: yup.string().required(REQUIRED_VALIDATION),
  [EMAIL]: yup
    .string()
    .email('Invalid email format')
    .required(EMAIL_VALIDATION),
});

export const createClientSchema = yup.object().shape({
  [NAME]: yup.string().required(REQUIRED_VALIDATION),
  [DESCRIPTION]: yup.string(),
  [SLUG]: yup
    .string()
    .required(REQUIRED_VALIDATION)
    .matches(/^[a-z0-9](-?[a-z0-9])*$/, SLUG_VALIDATION),
  [PLATFORM]: yup.boolean().default(false),
  [COUNTRY]: yup.string(),
});

export const modalApiKeySchema = yup.object().shape({
  [NAME]: yup.string().required(NAME_VALIDATION),
  restriction: yup.string(),
  services: yup.array(yup.string()).default([]),
  bundleId: yup.string().when('restriction', {
    is: (restriction: RestrictionsApiKey) =>
      [RestrictionsApiKey.ios, RestrictionsApiKey.android].includes(
        restriction,
      ),
    then: yup
      .string()
      .matches(/^[c][o][m][.].+[.].+$/, FORMAT_VALIDATION)
      .required(BUNDLEID_VALIDATION),
  }),
  fingerprint: yup.string().when('restriction', {
    is: RestrictionsApiKey.android,
    then: yup.string().required(FINGERPRINT_VALIDATION),
  }),
});

export const modalNewLicenseSchema = yup.object().shape({
  [NAME]: yup.string().required(REQUIRED_VALIDATION),
  [DESCRIPTION]: yup.string(),
  [ENDDATE]: yup
    .date()
    .min(new Date(), END_AFTER_START_VALIDATION)
    .required(END_DATE_VALIDATION),
  [LICENSETYPE]: yup.string().oneOf(Object.values(LicensesTypes)),
  [SERVICE]: yup.string().required(REQUIRED_VALIDATION),
  [BUNDLE_ID]: yup.string().when(SERVICE_NAME, {
    is: (service: LicenseService) =>
      service === LicenseService.sdkIos ||
      service === LicenseService.sdkAndroid,
    then: yup
      .string()
      .matches(/^[a-z0-9_-]+(\.[a-z0-9_-]+)+$/gi, FORMAT_VALIDATION)
      .required(REQUIRED_VALIDATION),
  }),
  [ORIGINS]: yup.array().of(
    yup.string().when(SERVICE_NAME, {
      is: (service: LicenseService) => service === LicenseService.sdkWeb,
      then: yup
        .string()
        .matches(URL_EXP, FORMAT_VALIDATION)
        .required(REQUIRED_VALIDATION),
    }),
  ),
});

export const assignServicesSchema = yup.object().shape({
  [ID]: yup.string().required(REQUIRED_VALIDATION),
  [COMPONENTS]: yup.array().of(yup.string()).nullable(),
});

export const editLicenseSchema = yup.object().shape({
  [NAME]: yup.string().required(NAME_VALIDATION),
  [LICENSE]: yup.string(),
  [ENDDATE]: yup.date().required(END_DATE_VALIDATION),
});
