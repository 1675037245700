import {
  ButtonIcon,
  CardBase,
  FlexContainer,
  GridContainer,
  Modal,
  setColorTheme,
  setShadowTheme,
} from '@facephi/ui-react';

import styled from 'styled-components/macro';

export const ClientsBaseStyle = styled(FlexContainer)`
  padding: 0 2.6rem;
`;

export const ClientsGridCardStyles = styled(CardBase)`
  position: relative;
  margin-top: 3rem;
  box-shadow: ${setShadowTheme('medium')};
  border: 1px solid transparent;
`;

export const ClientsGridCardLogoWrapper = styled('div')`
  position: absolute;
  left: 4rem;
  top: -3rem;
  height: 7.2rem;
  width: 7.2rem;

  img {
    min-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const ClientsLink = styled('a')`
  color: ${setColorTheme('primary400')};
  text-decoration: underline;
`;

export const ClientsGridCardContent = styled(FlexContainer)`
  padding: 0 4rem 2.5rem;
`;

export const ClientsGridCardFooter = styled(FlexContainer)`
  height: 6rem;
  border-top: 1px solid ${setColorTheme('grey200')};
  margin-top: auto;
`;

export const ClientsIdStyles = styled(FlexContainer)`
  height: 100%;
`;

export const ModalCreateClientStyles = styled(Modal)`
  width: 45rem;

  form {
    display: flex;
    flex: 1;
  }
`;

export const ClientsGridCardActions = styled(ButtonIcon)`
  margin: 0.8rem 0.8rem 0 auto;
`;

const MaxColumn = 6;
export const ColumnGap = '2.7';

export const CardContainer = styled(GridContainer)`
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      max(
        28.8rem,
        calc((100% - calc(${MaxColumn} * ${ColumnGap}rem)) / ${MaxColumn})
      ),
      1fr
    )
  );
`;
