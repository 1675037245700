import { gql } from '@apollo/client';

export const getApplicationsByClientId = gql`
  query getApplicationsByClientId($clientId: uuid!) {
    client_applications: clients_applications(
      where: { client_id: { _eq: $clientId } }
    ) {
      name: application
      id
    }

    applications {
      name
    }
  }
`;
