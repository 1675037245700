import { gql } from '@apollo/client';
import { tenantFragment } from '../fragments';

export const getTenants = gql`
  ${tenantFragment}
  query listTenants(
    $clientId: uuid!
    $name: String
    $offset: Int
    $limit: Int
  ) {
    clients_by_pk(id: $clientId) {
      tenants(
        where: { name: { _ilike: $name } }
        offset: $offset
        limit: $limit
        order_by: { created_at: desc }
      ) {
        ...tenantsFields
        users_tenants {
          user {
            id
            name
          }
        }
      }
      tenants_aggregate(where: { name: { _ilike: $name } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const getTenant = gql`
  query getTenant($tenantId: uuid!) {
    tenants_by_pk(id: $tenantId) {
      id
      name
      address
      timezone
      language
    }
  }
`;

export const getUsersByTenant = gql`
  query getUsersTenant($tenantId: uuid!) {
    users_tenants(where: { tenant_id: { _eq: $tenantId } }) {
      id
      user {
        id
        email
      }
    }
  }
`;
