import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Client } from '@cs/state/model';
import { getClient } from '@cs/state/queries';

export function useClient(id: string | undefined, skip = false) {
  const { data, loading } = useQuery<{ clients_by_pk: Client }>(getClient, {
    variables: {
      id,
    },
    nextFetchPolicy: 'cache-first',
    skip,
  });

  const client = useMemo(
    () =>
      data && !loading
        ? data.clients_by_pk
        : { name: '', id: '', slug: '', platform: false },
    [data, loading],
  );

  return {
    client,
    loading,
  };
}
