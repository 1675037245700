import { gql } from '@apollo/client';
import { apiKeysFragment } from '../fragments/apiKeys';

export const getApiKeys = gql`
  ${apiKeysFragment}
  query listApikeys(
    $clientId: uuid!
    $search: String
    $offset: Int
    $limit: Int
  ) {
    api_keys(
      where: { client_id: { _eq: $clientId }, name: { _ilike: $search } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      ...apiKeysFields
      api_keys_services {
        service_id
        id
        client_service_id
      }
    }
    total: api_keys_aggregate(
      where: { client_id: { _eq: $clientId }, name: { _ilike: $search } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getApiKeysAdmin = gql`
  ${apiKeysFragment}
  query listApikeysAdmin($search: String, $offset: Int, $limit: Int) {
    api_keys(
      where: { client_id: { _is_null: true }, name: { _ilike: $search } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      ...apiKeysFields
      api_keys_services {
        service_id
      }
    }
    total: api_keys_aggregate(
      where: { client_id: { _is_null: true }, name: { _ilike: $search } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
