import { useEffect, useState } from 'react';
import { Icon, Label, useTimezone } from '@facephi/ui-react';
import { TimelineProps, TimelineStatusType } from './model';
import { TimelineStyles } from './Styles';

export const Timeline = ({ startDate, endDate }: TimelineProps) => {
  const [status, setStatus] = useState<TimelineStatusType>();
  const { formatTimezone, getDateTimezone } = useTimezone();

  const getStatus = (progress: number): TimelineStatusType => {
    // Low
    if (progress >= 0 && progress < 45) {
      return {
        icon: 'CaretDoubleDown',
        color: 'skyBlue',
        width: `${progress.toFixed(2)}%`,
      };
    }
    // Middle
    if (progress >= 45 && progress <= 55) {
      return {
        icon: 'Equals',
        color: 'skyBlue',
        width: `${progress.toFixed(2)}%`,
      };
    }
    // Error
    if (progress < 0) {
      return {
        icon: 'WarningCircle',
        color: 'error',
        width: '100%',
      };
    }
    // High
    return {
      icon: 'CaretDoubleUp',
      color: 'warning',
      width: `${progress.toFixed(2)}%`,
    };
  };

  useEffect(() => {
    const currentDate = getDateTimezone(new Date().getTime());
    const initialDate = getDateTimezone(startDate);
    const finishDate = getDateTimezone(endDate);

    const totalTime = finishDate.diff(initialDate, 'days');
    const currentRemaining = finishDate.diff(currentDate, 'days');
    const timeRemaining = 100 - (currentRemaining * 100) / totalTime;

    setStatus(getStatus(isNaN(timeRemaining) ? 99 : timeRemaining));
  }, [startDate, endDate]);

  return (
    <TimelineStyles
      alignItems="center"
      columnGap="0.4"
      $width={status?.width}
      $background={status?.color}
    >
      {status?.icon && <Icon iconName={status?.icon} color="white" size="16" />}
      <Label fontSize="12" negative as="span">
        {formatTimezone(startDate, 'DD/MM/YYYY')} -
      </Label>
      <Label fontSize="12" negative as="span">
        {formatTimezone(endDate, 'DD/MM/YYYY')}
      </Label>
    </TimelineStyles>
  );
};
