import React, { ReactNode } from 'react';
import { ButtonIcon, FlexContainer, Label } from '@facephi/ui-react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { AsideNav, AsideNavProps } from '../asideNav';
import { Header } from '../header';
import {
  LayoutContent,
  LayoutHeader,
  LayoutStyles,
  LayoutWrapper,
} from './Styles';

export type LayoutProps = AsideNavProps & {
  children: ReactNode;
  title?: string;
  goBackRoute?: string | [string, NavigateOptions];
  image?: string;
  leftPanel?: React.ReactNode;
};

export const Layout = ({
  children,
  title,
  goBackRoute,
  ...rest
}: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <LayoutStyles>
      <AsideNav {...rest} />
      <LayoutWrapper flexDirection="column">
        <LayoutHeader
          alignItems="center"
          justifyContent={title ? 'space-between' : 'flex-end'}
          forwardedAs="header"
          columnGap="1"
          $hasGoBack={goBackRoute !== ''}
        >
          {goBackRoute && (
            <FlexContainer alignItems="center" columnGap="2.1">
              <ButtonIcon
                iconName="CaretLeft"
                onClick={() =>
                  typeof goBackRoute === 'string'
                    ? navigate(goBackRoute)
                    : navigate(...goBackRoute)
                }
                testId="go-back-button"
              />
              <Label as="h1" fontSize="21" semibold>
                {title}
              </Label>
            </FlexContainer>
          )}
          {title && !goBackRoute && (
            <Label as="h1" fontSize="21" semibold>
              {title}
            </Label>
          )}
          <Header />
        </LayoutHeader>
        <LayoutContent flexDirection="column">{children}</LayoutContent>
      </LayoutWrapper>
    </LayoutStyles>
  );
};
