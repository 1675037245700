import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Timezone } from '@cs/state/model';
import { getTimezones } from '@cs/state/queries';
import { DropdownOption } from '@facephi/ui-react';

export function useTimezone() {
  const { data } = useQuery<{ timezone: Timezone[] }>(getTimezones);

  const optionsTimeZone: DropdownOption[] = useMemo(
    () =>
      data
        ? data.timezone.map((item) => ({
            name: item.timezone,
            value: item.timezone,
          }))
        : [],
    [data],
  );
  return {
    optionsTimeZone,
  };
}
