import { IconName, InphiniteThemeBaseColors } from '@facephi/ui-react';

export type ApplicationDto = {
  name: string;
  active?: boolean;
  id?: string;
};

export type ApplicationsQueryDto = {
  applications: ApplicationDto[];
  client_applications: ApplicationDto[];
};

export const ApplicationIcon: {
  [key: string]: { icon: IconName; color: InphiniteThemeBaseColors };
} = {
  operations: {
    icon: 'ChartPie',
    color: 'yellow',
  },
  builder: {
    icon: 'PencilCircle',
    color: 'pink',
  },
  ux: {
    icon: 'Smiley',
    color: 'blue',
  },
  privacy: {
    icon: 'Lock',
    color: 'skyBlue',
  },
  iaocr: {
    icon: 'Brain',
    color: 'green',
  },
  antifraud: {
    icon: 'FraudIcon',
    color: 'tomato',
  },
  videoassistance: {
    icon: 'Headset',
    color: 'purple',
  },
  behavior: {
    icon: 'GitDiff',
    color: 'cyan',
  },
  ciam: {
    icon: 'LineSegments',
    color: 'skyBlue',
  },
};
