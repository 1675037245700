import { useMutation } from '@apollo/client';
import { useToast } from '@facephi/ui-react';
import { DocumentNode } from 'graphql';
import { useTranslation } from 'react-i18next';

export function useDelete(
  deleteMutation: DocumentNode,
  undoDeleteMutation: DocumentNode,
  typename: 'users' | 'tenants' | 'api_keys' | 'clients' | 'request',
) {
  const { toastManager } = useToast();
  const { t } = useTranslation();

  const [deleteElement] = useMutation(deleteMutation);
  const [undoDeleteElement] = useMutation(undoDeleteMutation);

  const handleUndoDelete = async (id: string) => {
    await undoDeleteElement({
      variables: { id },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t(`${typename} revert`),
          testId: `toast-revert-delete-${typename}`,
          duration: 3000,
        });
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          testId: `toast-revert-delete-${typename}`,
        });
      },
    });
  };

  const handleDelete = async (id: string) => {
    await deleteElement({
      variables: { id },
      update: (cache) => {
        cache.evict({ id: cache.identify({ id, __typename: typename }) });
        cache.gc();
      },
      onCompleted: () => {
        toastManager({
          type: 'error',
          message: t(`${typename} deleted`),
          testId: `toast-delete-${typename}`,
          iconName: 'Trash',
          buttonMessage: t('Undo'),
          onClick: () => handleUndoDelete(id),
          duration: 3000,
        });
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          testId: `toast-delete-${typename}`,
        });
      },
    });
  };

  return { handleDelete, handleUndoDelete };
}
