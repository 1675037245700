/* eslint-disable @typescript-eslint/no-explicit-any */
export enum LicensesTypes {
  Postpaid = 'Postpaid',
  Prepaid = 'Prepaid',
  Perpetual = 'Perpetual',
}

export type NewLicenseDto = {
  clientId?: string;
  id?: string;
  name?: string;
  license?: any;
  endDate?: string;
  licenseType?: string;
  description?: string;
  platform?: string;
  service?: string;
  serviceName?: string;
  serviceId?: string;
  bundleId?: string;
  origin?: string;
  origins?: string[];
  apikey?: string;
};

export type LicenseDto = {
  id: string;
  license: string;
  bundle_id: string;
  name: string;
  created_at: string;
  valid_until: string;
  active: boolean;
  description: string;
  apikey: string;
  origin: string;
  origins: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  licenses: any;
  clients_service: {
    id: string;
    service: {
      name: string;
      id: string;
    };
  };
};
