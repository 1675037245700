import { ClientsDto } from '@cs/state/model';
import { ClientsGridCard } from './ClientsGridCard';
import { CardContainer, ColumnGap } from './Styles';

export const ClientsGrid = ({
  clients,
  testId,
}: {
  clients: ClientsDto[];
  testId?: string;
}) => {
  return (
    <CardContainer gap={ColumnGap} testId={testId}>
      {clients.map((client, index) => (
        <ClientsGridCard key={client.id} testId={`${index}`} client={client} />
      ))}
    </CardContainer>
  );
};
