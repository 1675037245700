import { ReactNode } from 'react';
import { useMutation } from '@apollo/client';
import { editLicenseSchema, LicenseDto, NewLicenseDto } from '@cs/state/model';
import { modifyLicense } from '@cs/state/mutations';
import {
  Button,
  CardBase,
  CardBaseFooter,
  CardBaseHeader,
  FlexContainer,
  Label,
  Status,
  useTimezone,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Timeline } from '../timeline';
import {
  LicenseIdCardList,
  LicenseIdDatePicker,
  LicenseIdElement,
  LicenseIdInput,
  LicenseIdInputDatePicker,
  LicenseIdTextArea,
} from './Styles';

type Props = {
  license: LicenseDto;
};

type dataType = {
  title: string;
  message: ReactNode;
};

export const LicenseIdCardLeft = ({ license }: Props) => {
  const { t } = useTranslation();
  const { getDateTimezone, getTimeFromNow } = useTimezone();
  const { toastManager } = useToast();

  const [updateLicense] = useMutation(modifyLicense);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(editLicenseSchema),
    defaultValues: {
      name: license.name,
      license: license.license,
      endDate: new Date(license.valid_until),
    },
  });

  const onSubmit = async (data: NewLicenseDto) => {
    await updateLicense({
      variables: {
        id: license?.id,
        name: data.name,
        license: data.license,
        validUntil: data.endDate,
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('License modified successfully'),
          testId: 'toast-modify-license',
        });
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Somenthing went wrong, try again'),
          testId: 'toast-modify-error',
        });
      },
    });
  };

  const data: dataType[] = [
    {
      title: t('Name'),
      message: (
        <LicenseIdInput
          {...register('name')}
          testId="license-name"
          errorLabel={errors.name?.message && t(errors.name.message)}
        />
      ),
    },
    {
      title: t('License'),
      message: (
        <LicenseIdTextArea
          {...register('license')}
          rows={4}
          testId="license-license"
          errorLabel={errors.license?.message && t(errors.license.message)}
        />
      ),
    },
    {
      title: t('Start date'),
      message: (
        <LicenseIdDatePicker
          testId="license-start-date"
          value={new Date(license.created_at)}
          disabled
        />
      ),
    },
    {
      title: t('End date'),
      message: (
        <Controller
          name="endDate"
          control={control}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={({ field }: any) => (
            <LicenseIdInputDatePicker
              {...field}
              testId="license-end-date"
              minDate={getDateTimezone(new Date().getTime()).toDate()}
              errorLabel={errors.endDate?.message && t(errors.endDate.message)}
            />
          )}
        />
      ),
    },
    {
      title: t('Nº of use'),
      message: <Label fontSize="14">0</Label>,
    },
    {
      title: t('Next interaction'),
      message: (
        <Label fontSize="14">
          {license && getTimeFromNow(license?.valid_until)}
        </Label>
      ),
    },
    {
      title: t('Timeline'),
      message: license && (
        <Timeline
          startDate={license.created_at}
          endDate={license.valid_until}
        />
      ),
    },
  ];

  return (
    <CardBase flex="2" flexDirection="column">
      <CardBaseHeader>
        <Label fontSize="14" semibold>
          {t('License information')}
        </Label>
        <Status
          status={license?.active ? 'ACTIVATED' : 'NOT_ACTIVATED'}
          hasLabel
        />
      </CardBaseHeader>
      <FlexContainer as="form" flexDirection="column" flex="1">
        <LicenseIdCardList flexDirection="column" forwardedAs="ul" flex="1">
          {data.map(({ title, message }, index) => (
            <LicenseIdElement
              key={index}
              forwardedAs="li"
              alignItems="center"
              justifyContent="space-between"
              columnGap="1.6"
            >
              <Label fontSize="14" semibold>
                {title}
              </Label>
              {message}
            </LicenseIdElement>
          ))}
        </LicenseIdCardList>
        <CardBaseFooter hasPadding>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            testId="button-edit-license"
          >
            {t('Update')}
          </Button>
        </CardBaseFooter>
      </FlexContainer>
    </CardBase>
  );
};
