import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { User } from '@cs/state/model';
import { getUsersSearch } from '@cs/state/queries';
import { DropdownSearchOption } from '@facephi/ui-react';

import { useSearch } from './useSearch';

type UserAdd = DropdownSearchOption & {
  add: boolean;
  lastName: string;
  firstName: string;
  password: string;
};

export function useSearchUser(clientId: string) {
  const { onHandleSearch, search } = useSearch();
  const [users, setUsers] = useState<UserAdd[]>([]);
  const [addUser, setAddUser] = useState<boolean>(false);

  const { data } = useQuery(getUsersSearch, {
    variables: {
      search,
      clientId: clientId,
    },
    skip: !search || search.length < 3,
  });

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const resetUsers = () => {
    setUsers([]);
  };

  useEffect(() => {
    if (data) {
      if (data.users.users.length) {
        setUsers(
          data.users.users.map((i: User) => ({
            name: i.email,
            value: i.email,
            icon: 'ListPlus',
            lastName: i.lastName,
            firstName: i.firstName,
            password: '******',
            add: false,
          })),
        );
      } else {
        const isEmail = isValidEmail(search);

        if (isEmail && !data.exist) {
          setUsers([
            {
              name: search,
              value: search,
              icon: 'Plus',
              lastName: '',
              firstName: '',
              password: '',
              add: true,
            },
          ]);
        }
      }
    }
  }, [data, search]);

  return {
    search,
    onHandleSearch,
    users,
    resetUsers,
    addUser,
    setAddUser,
  };
}
