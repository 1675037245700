import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { TabSearchingHeader } from '@cs/components';
import { usePagination, useSearch } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { MaxItemsPag, RoutesName } from '@cs/state/constants';
import { APiKeyItemDto, ApiKeyResultsDto, SCOPES } from '@cs/state/model';
import { getApiKeys, getApiKeysAdmin } from '@cs/state/queries';
import { CardBase } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';
import { ModalApiKey } from './ModalApiKey';
import { TableApiKeys } from './TableApiKeys';

type IProps = {
  admin?: boolean;
};

export const ApiKeys = ({ admin = false }: IProps) => {
  const { t } = useTranslation();
  const { setTitle, setGoBackRoute } = useLayout();
  const [editableItem, setEditableItem] = useState<APiKeyItemDto | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const routeParams = useParams();

  const { onHandleSearch, search } = useSearch();

  const variables = useMemo(
    () => ({
      clientId: routeParams.id,
      search: `%${search}%`,
      limit: MaxItemsPag,
    }),
    [routeParams, search],
  );

  const { data, loading, fetchMore, refetch, error } =
    useQuery<ApiKeyResultsDto>(admin ? getApiKeysAdmin : getApiKeys, {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...variables,
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  const apiKeysData = useMemo(() => (data ? data.api_keys : []), [data]);
  const total = useMemo(() => (data ? data.total.aggregate.count : 0), [data]);

  const { handlePagination, hasMore, scrollPosition } = usePagination(
    apiKeysData,
    loading,
    fetchMore,
    total,
  );

  const onEditHandle = (apiKey: APiKeyItemDto) => {
    setShowModal(true);
    setEditableItem(apiKey);
  };

  const forceUpdateQuery = () => {
    refetch(variables);
  };

  const { emptyState } = useEmptyState({
    data: data?.api_keys || [],
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(search.length),
    onRetry: forceUpdateQuery,
  });

  useEffect(() => {
    if (!showModal) {
      setEditableItem(undefined);
    }
  }, [showModal]);

  useEffect(() => {
    setTitle(t('Api Keys list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  return (
    <>
      <CardBase flexDirection="column" flex="1">
        <TabSearchingHeader
          onChange={onHandleSearch}
          onCreate={() => setShowModal(true)}
          permissionsCreate={[SCOPES.canWriteApiKeys]}
        />
        <TableApiKeys
          apikeys={apiKeysData}
          onEdit={onEditHandle}
          hasMore={hasMore}
          scrollPosition={scrollPosition}
          fetchMore={handlePagination}
          loading={loading}
          emptyStateData={emptyState}
        />
      </CardBase>
      {showModal && (
        <ModalApiKey
          show={showModal}
          onChangeShow={(show) => setShowModal(show)}
          data={editableItem}
          onRefetch={() => refetch()}
          admin={admin}
        />
      )}
    </>
  );
};
