import { TemplatesType, FieldTemplateProps } from '@rjsf/utils';
import { FieldStyled } from './Styles';

const FieldTemplate = ({ classNames, children }: FieldTemplateProps) => {
  return (
    <FieldStyled flex="1" flexDirection="column" className={classNames}>
      {children}
    </FieldStyled>
  );
};

const ErrorListTemplate = () => {
  return <></>;
};

export const customTemplates: Partial<TemplatesType> = {
  FieldTemplate: FieldTemplate,
  ErrorListTemplate: ErrorListTemplate,
};
