import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { insertCommentRequest } from '@cs/state/mutations';
import { TextArea, useToast } from '@facephi/ui-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledModalComment, StyledModalContent } from './Styles';

type Inputs = {
  comment: string;
};

type IProps = {
  show: boolean;
  onChangeShow: (show: boolean) => void;
  requestId?: string;
};

export const ModalComment = ({ show, onChangeShow, requestId }: IProps) => {
  const { t } = useTranslation();

  const { toastManager } = useToast();

  const { handleSubmit, register, reset } = useForm<Inputs>();
  const [insertComment] = useMutation(insertCommentRequest);

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await insertComment({
      variables: {
        comment: data.comment,
        requestId,
      },
      onCompleted() {
        toastManager({
          message: t('Comment sent correctly'),
          type: 'success',
          testId: 'comment-sended',
        });

        onChangeShow(false);
      },
      onError() {
        toastManager({
          message: t('There has been an error, try again later.'),
          type: 'error',
          testId: 'comment-error',
        });
      },
    });
  };

  return (
    <StyledModalComment
      header={{
        iconName: 'Pencil',
        title: t('Comment Issue'),
      }}
      footer={{
        closeButtonLabel: t('Close'),
        submitButtonLabel: t('Send'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      show={show}
      onChangeShow={onChangeShow}
      isForm
    >
      <StyledModalContent flex="1" flexDirection="column">
        <TextArea label={t('Comment')} {...register('comment')} />
      </StyledModalContent>
    </StyledModalComment>
  );
};
