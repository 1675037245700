import axios, { ResponseType } from 'axios';

export enum RequestMethods {
  post = 'post',
  get = 'get',
}

export type RequestOptions = {
  method: RequestMethods;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  responseType?: ResponseType;
};

export function useRequest(token?: string) {
  const getAuthHeader = () =>
    token ? { Authorization: `Bearer ${token}` } : {};

  const request = (url: string, options: RequestOptions) =>
    axios({
      method: options.method,
      url,
      data: options.data,
      headers: getAuthHeader(),
      responseType: options.responseType || 'json',
    })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error);
      });

  const requestUpload = async (url: string, file: File, clientId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('client', clientId);

    return axios({
      method: RequestMethods.post,
      url: url,
      data: formData,
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  return {
    request,
    requestUpload,
  };
}
