import { useAuthService } from '@cs/providers';
import { RequestMethods, useRequest } from './useRequest';

type IUpload = {
  clientId: string;
  file: File;
};

export function useApi() {
  const { token } = useAuthService();
  const { request, requestUpload } = useRequest(token || '');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiUrl: string = (window as any)._env_.REACT_APP_NESTJS_URL;

  const uploadFile = ({ clientId, file }: IUpload) =>
    requestUpload(`${apiUrl}/upload`, file, clientId);

  const getFile = async (fileId: string) => {
    try {
      const response = await request(`${apiUrl}/media/${fileId}`, {
        method: RequestMethods.get,
        responseType: 'blob',
      });

      return response;
    } catch (e) {
      return null;
    }
  };
  return { uploadFile, getFile };
}
