import { useEffect } from 'react';
import { useSlug } from '@cs/hooks';
import { DESCRIPTION, NAME, SLUG } from '@cs/state/constants';
import {
  Client,
  createClientSchema,
  ModalCreateClientProps,
} from '@cs/state/model';
import {
  FlexContainer,
  Input,
  Label,
  Switch,
  DropdownFlags,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalCreateClientStyles } from './Styles';

export const ModalCreateClient = ({
  className,
  onSave,
  onChangeShow,
  show,
}: ModalCreateClientProps) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createClientSchema),
    mode: 'onChange',
  });

  const { value } = useSlug({ control, name: 'name' });
  const { value: valueOriginal } = useSlug({ control, name: 'slug' });

  const onSubmit = (newClient: Client) => {
    if (newClient) {
      onSave(newClient);
    }
  };

  useEffect(() => {
    if (valueOriginal) {
      setValue('slug', valueOriginal);
    }
  }, [valueOriginal]);

  useEffect(() => {
    if (value) {
      setValue('slug', value);
    }
  }, [value]);

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  return (
    <ModalCreateClientStyles
      show={show}
      header={{
        iconName: 'PlusCircle',
        title: t('Create a new client'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      hasPadding
      testId="modal-create-client"
      onChangeShow={onChangeShow}
      overlayBlur
      className={className}
    >
      <FlexContainer
        as="form"
        flexDirection="column"
        rowGap="1.6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Label fontSize="14" htmlFor="name">
          {t('Name')}
        </Label>
        <Input
          testId="client-input-name"
          id="name"
          autoFocus
          {...register(NAME)}
          errorLabel={errors.name?.message && t(errors.name.message)}
        />

        <Label fontSize="14" htmlFor="slug">
          {t('Slug')}
        </Label>

        <Input
          testId="client-input-slug"
          id="slug"
          {...register(SLUG)}
          errorLabel={errors.slug?.message && t(errors.slug.message)}
        />

        <Label fontSize="14" htmlFor="description">
          {t('Description')}
        </Label>
        <Input
          testId="client-input-description"
          id="description"
          {...register(DESCRIPTION)}
          errorLabel={errors.name?.message && t(errors.name.message)}
        />

        <Controller
          name="platform"
          control={control}
          render={({ field }) => {
            return (
              <Switch
                labelRight={t('With Platform')}
                checked={field.value}
                name={field.name}
                onChange={field.onChange}
              />
            );
          }}
        />

        <Label fontSize="14" htmlFor="description">
          {t('Country')}
        </Label>

        <Controller
          name="country"
          control={control}
          render={({ field }) => {
            return (
              <DropdownFlags
                {...field}
                locale={i18n.language}
                autoComplete="off"
              />
            );
          }}
        />
      </FlexContainer>
    </ModalCreateClientStyles>
  );
};
