import { MouseEvent } from 'react';
import { FlexContainer, Label, ModalStateProps } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { ModalLogOutLabel, ModalLogOutStyles } from './Styles';

type Props = ModalStateProps & {
  onLogOut?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const ModalLogOut = ({ onChangeShow, show, onLogOut }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalLogOutStyles
      overlayBlur
      show={show}
      onChangeShow={onChangeShow}
      header={{ noBorder: true }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('LogOut'),
        onSubmitButton: onLogOut,
      }}
      hasPadding
    >
      <FlexContainer flex="1" flexDirection="column" alignItems="center">
        <img src="/logout.svg" alt="logout" />
        <ModalLogOutLabel fontSize="14" semibold textAlign="center">
          {t('Please don’t go.')}
        </ModalLogOutLabel>
        <Label fontSize="12" textAlign="center">
          {t('Are you sure do you want to log out?')}
        </Label>
      </FlexContainer>
    </ModalLogOutStyles>
  );
};
