/* eslint-disable @typescript-eslint/no-explicit-any */
const apiUrl: string = (window as any)._env_.REACT_APP_AUTH_API_URL;
const clientId: string = (window as any)._env_.REACT_APP_AUTH_CLIENT_ID;
const realm: string = (window as any)._env_.REACT_APP_AUTH_REALM;
const appUrl =
  window.location.hostname === 'localhost'
    ? `${window.location.protocol}//${window.location.hostname}:3000`
    : `${window.location.protocol}//${window.location.hostname}`;

const onSigninCallback = (): void => {
  if (sessionStorage.getItem('route')) {
    window.location.replace(sessionStorage.getItem('route') as string);
    sessionStorage.deleteItem('route');
  }
  window.history.replaceState(
    {},
    document.title,
    sessionStorage.getItem('route') || window.location.pathname,
  );
};

const oidcConfig = {
  authority: `${apiUrl}/auth/realms/${realm}`,
  client_id: clientId,
  redirect_uri: `${appUrl}/login`,
  post_logout_redirect_uri: `${appUrl}`,
  onSigninCallback,
};

export default oidcConfig;
