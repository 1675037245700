import { useEffect, useMemo } from 'react';
import { assignServicesSchema, Service, ServiceForm } from '@cs/state/model';
import { Dropdown, FlexContainer, ModalStateProps } from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useComponentServices, useServicesAvailable } from '../../hooks';
import { ModalAssignServiceStyles } from './Styles';

export type ModalAssignServiceProps = ModalStateProps & {
  onSave(newService: Service): void;
  routeId?: string;
  service?: Service;
};

export const ModalAssignService = ({
  onSave,
  onChangeShow,
  show,
  service,
  routeId,
}: ModalAssignServiceProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<ServiceForm>({
    defaultValues: service
      ? {
          id: service.serviceId,
        }
      : undefined,
    resolver: yupResolver(assignServicesSchema),
  });

  const { services, updateServices } = useServicesAvailable(routeId);
  const { getComponents, components, componentsClients, getClientComponents } =
    useComponentServices();

  const onSubmit = (newService: Service) => {
    if (newService) {
      onSave(newService);
    }
    reset({});
  };

  useEffect(() => {
    if (service) {
      getClientComponents({ variables: { serviceId: service.id } });
    }
  }, [service]);

  useEffect(() => {
    if (componentsClients.length) {
      setValue('components', componentsClients);
    }
  }, [componentsClients]);

  const serviceWatch = useWatch({ name: 'id', control });

  useEffect(() => {
    if (serviceWatch) {
      getComponents({ variables: { serviceId: serviceWatch } });
    }
  }, [serviceWatch]);

  useEffect(() => {
    updateServices();
  }, [show]);

  const haveComponents = useMemo(
    () => (components.length ? true : false),
    [components],
  );

  return (
    <ModalAssignServiceStyles
      show={show}
      testId="modal-assign-service"
      onChangeShow={onChangeShow}
      header={{
        iconName: 'PlusCircle',
        title: t(service ? 'Edit a service' : 'Assign a new service'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      overlayBlur
      isForm
      hasPadding
    >
      <FlexContainer as="form" flexDirection="column" rowGap="1.6" flex="1">
        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('Select a service')}
              errorLabel={errors.id?.message && t(errors.id.message)}
              testId="services-select-service"
              options={services}
              placeholder={service ? service.name : undefined}
              disabled={service ? true : false}
            />
          )}
        />
        {haveComponents && (
          <Controller
            name="components"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                label={t('Select components')}
                testId="services-select-component"
                multiple
                options={components}
              />
            )}
          />
        )}
      </FlexContainer>
    </ModalAssignServiceStyles>
  );
};
