import { ButtonIcon, FlexContainer, Label } from '@facephi/ui-react';
import { CardTenantAsignedStyles } from './Styles';

type Props = {
  label?: string;
  onDelete?(): void;
  onEdit?(): void;
};

export const CardTenantAsigned = ({ label, onDelete, onEdit }: Props) => {
  return (
    <CardTenantAsignedStyles alignItems="center" justifyContent="space-between">
      <Label fontSize="10">{label}</Label>
      <FlexContainer alignItems="center">
        {onEdit && (
          <ButtonIcon
            iconName="PencilSimpleLine"
            variant="text"
            size="S"
            onClick={onEdit}
          />
        )}

        {onDelete && (
          <ButtonIcon
            iconName="LinkBreak"
            variant="text"
            size="S"
            onClick={onDelete}
          />
        )}
      </FlexContainer>
    </CardTenantAsignedStyles>
  );
};
