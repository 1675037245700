import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { Button, FlexContainer, Input } from '@facephi/ui-react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SCOPES } from '../../state/model';
import { PermissionsGate } from '../permissions';
import { StyledContainer } from './Styles';

type Props = {
  className?: string;
  onCreate?(): void;
  filterMenu?: ReactNode;
  onChange(value?: ChangeEvent<HTMLInputElement>): void;
  buttonLabel?: string;
  permissionsCreate?: SCOPES[];
  withPadding?: boolean;
};

export const SearchingHeader = ({
  className,
  onCreate,
  filterMenu,
  onChange,
  buttonLabel = 'Create',
  permissionsCreate = [],
  withPadding = false,
}: Props) => {
  const { t } = useTranslation();
  const [innerText, setInnerText] = useState<string>('');

  const debouncedOnChange = useMemo(
    () => onChange && debounce(onChange, 250),
    [],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInnerText(event.target.value);
    debouncedOnChange?.(event);
  };

  return (
    <StyledContainer
      className={className}
      justifyContent="space-between"
      alignItems="center"
      $withPadding={withPadding}
    >
      <FlexContainer
        columnGap="1.6"
        rowGap="1.6"
        wrap="wrap"
        alignItems="center"
      >
        <Input
          type="text"
          iconLeft="MagnifyingGlass"
          placeholder={t('Search for...')}
          value={innerText}
          onChange={handleInputChange}
        />
        {filterMenu}
      </FlexContainer>
      {onCreate && (
        <PermissionsGate scopes={permissionsCreate}>
          <Button testId="button-create" iconName="Plus" onClick={onCreate}>
            {t(buttonLabel)}
          </Button>
        </PermissionsGate>
      )}
    </StyledContainer>
  );
};
