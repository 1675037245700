import { gql } from '@apollo/client';

export const assignRole = gql`
  mutation assignRole($role: users_role_insert_input!) {
    insert_users_role_one(object: $role) {
      id
    }
  }
`;

export const updateRole = gql`
  mutation updateRole(
    $userId: uuid!
    $tenantId: uuid!
    $role: users_role_set_input!
  ) {
    update_users_role_by_pk(
      pk_columns: { tenant_id: $tenantId, user_id: $userId }
      _set: $role
    ) {
      id
    }
  }
`;
