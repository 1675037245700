import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useCopyToClipboard } from '@cs/hooks';
import { LicenseDto, NewLicenseDto, SCOPES, TableProps } from '@cs/state/model';
import { removeLicense } from '@cs/state/mutations';
import {
  Button,
  ButtonIcon,
  Icon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  Status,
  Table as TableSelection,
  useToast,
} from '@facephi/ui-react';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { PermissionsGate } from '../permissions';
import {
  StyledContainerOrigins,
  StyledMultipleOrigins,
  StyledTableButton,
} from './Styles';

type LicenseTableData = {
  id: string;
  name: string;
  active: boolean;
  validUntil: string;
  createdAt: string;
  nOfUser: number;
  type: string;
  endDate: string;
  licenseType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  license: any;
  description: string;
  platform: string;
  service: string;
  serviceId: string;
  serviceName: string;
  bundleId: string;
  origin: string;
};

type Props = TableProps & {
  licenses?: LicenseDto[];
  onSeeDetail?(id: LicenseTableData): void;
  onActivate(id: string): void;
  onEdit(license: NewLicenseDto): void;
  onClone(license: NewLicenseDto): void;
};

export const TableLicenses = ({
  onSeeDetail,
  licenses,
  loading,
  hasMore,
  fetchMore,
  emptyStateData,
  onEdit,
  onClone,
  scrollPosition,
  onActivate,
}: Props) => {
  const { t } = useTranslation();
  const { toastManager } = useToast();

  const [deleteLicense] = useMutation(removeLicense);
  const { copy } = useCopyToClipboard();

  const onDeleteLicense = async (id: string) => {
    await deleteLicense({
      variables: { id },
      update: (cache) => {
        cache.evict({ id: cache.identify({ id, __typename: 'licenses' }) });
        cache.gc();
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          testId: 'toast-delete-license-error',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('License deleted'),
          testId: 'toast-delete-license',
        });
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        header: t('Name'),
        accessorKey: 'name',
      },
      {
        header: t('Bundle Id'),
        accessorKey: 'bundleId',
      },
      {
        header: t('Origin'),
        minWidth: 200,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (props: any) => {
          const origins: string[] = props.row.original.origin?.split(',');
          return (
            <>
              {!origins ? null : (
                <StyledContainerOrigins alignItems="center">
                  <Label ellipsis>{origins[0]}</Label>
                  {origins.length > 1 && (
                    <StyledMultipleOrigins alignItems="center">
                      <OptionMenu
                        renderItem={
                          <StyledTableButton
                            variant="text"
                            iconName="CaretDown"
                            iconPosition="right"
                            size="S"
                          >
                            {`(+ ${origins.length - 1})`}
                          </StyledTableButton>
                        }
                      >
                        {origins.map((origin, index) => (
                          <OptionMenuItem key={index}>{origin}</OptionMenuItem>
                        ))}
                      </OptionMenu>
                    </StyledMultipleOrigins>
                  )}
                </StyledContainerOrigins>
              )}
            </>
          );
        },
      },
      {
        header: t('Service'),
        accessorKey: 'service',
      },
      {
        header: t('Valid until'),
        accessorKey: 'endDate',
      },
      {
        header: t('Apikey'),
        accessorKey: 'apikey',
        minWidth: 350,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (props: any) => {
          return (
            <Button
              variant="text"
              type="button"
              semiBold={false}
              onClick={() => copy(props.row.original.apikey)}
            >
              {props.row.original.apikey}

              <Icon iconName="Copy" size="16" />
            </Button>
          );
        },
      },
      {
        header: t('Status'),
        accessorKey: 'status',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (row: any) => {
          return row.renderValue();
        },
      },
      {
        header: t('Actions'),
        accessorKey: 'actions',
        cell: (props: CellProps<LicenseTableData>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              widthAuto
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId={`option-menu-license-${props.row.id}`}
            >
              {onSeeDetail && (
                <OptionMenuItem
                  testId="button-view-license"
                  iconName="MagnifyingGlassPlus"
                  onClick={() => onSeeDetail(props.cell.row.original)}
                >
                  {t('View')}
                </OptionMenuItem>
              )}
              <PermissionsGate scopes={[SCOPES.canWriteLicenses]}>
                {!props.row.original.active && (
                  <OptionMenuItem
                    testId="button-activate-license"
                    iconName="CheckCircle"
                    onClick={() => onActivate(props.cell.row.original.id)}
                  >
                    {t('Activate')}
                  </OptionMenuItem>
                )}
                <OptionMenuItem
                  testId="button-edit-license"
                  iconName="NotePencil"
                  onClick={() => {
                    onEdit(props.cell.row.original as NewLicenseDto);
                  }}
                >
                  {t('Edit')}
                </OptionMenuItem>
                <OptionMenuItem
                  testId="button-clone-license"
                  iconName="Copy"
                  onClick={() => {
                    onClone(props.cell.row.original as NewLicenseDto);
                  }}
                >
                  {t('Clone')}
                </OptionMenuItem>

                <OptionMenuItem
                  testId="button-delete-license"
                  iconName="Trash"
                  color="error"
                  onClick={() => {
                    onDeleteLicense(props.cell.row.original.id);
                  }}
                >
                  {t('Delete')}
                </OptionMenuItem>
              </PermissionsGate>
            </OptionMenu>
          );
        },
        maxWidth: 100,
      },
    ],
    [],
  );

  const data = useMemo(() => {
    return (
      licenses?.map((license: LicenseDto, index: number) => ({
        id: license.id,
        name: license.name,
        bundleId: license.bundle_id,
        origin: license.origins ? license.origins.join(',') : license.origin,
        origins: license.origins,
        service: license.clients_service?.service?.name,
        serviceId: license.clients_service?.id,
        active: license.active,
        endDate: dayjs(license.valid_until).format('DD-MM-YYYY'),
        createdAt: license.created_at,
        description: license.description,
        apikey: license.apikey,
        license: license.licenses,
        status: (
          <Status
            status={license.active ? 'ACTIVATED' : 'NOT_ACTIVATED'}
            hasLabel
            testId={license.active ? `active-${index}` : `not-active-${index}`}
          />
        ),
      })) || []
    );
  }, [licenses]);

  return (
    <TableSelection
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns={columns as any}
      data={data}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      scrollPosition={scrollPosition}
      emptyStateData={emptyStateData}
    />
  );
};
