import { useState } from 'react';
import { URL_EXP } from '@cs/state/constants';

export function useUrl() {
  const [error, setError] = useState<boolean>(false);

  const validUrl = (url: string) => {
    const isValid = URL_EXP.test(url);

    if (!isValid) setError(true);

    return isValid;
  };

  const clearError = () => setError(false);

  return {
    error,
    validUrl,
    clearError,
  };
}
