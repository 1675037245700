import { ChangeEvent, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  LicenseWrapper,
  ModalDetailLicense,
  ModalNewLicense,
  TableLicenses,
  TabSearchingHeader,
} from '@cs/components';
import { useLicense, useScrollPosition } from '@cs/hooks';
import { MaxItemsPag, RoutesName } from '@cs/state/constants';
import { LicenseDto, NewLicenseDto, SCOPES } from '@cs/state/model';
import { activateLicense } from '@cs/state/mutations';
import { getLicenses } from '@cs/state/queries';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useEmptyState } from '../../hooks/useEmptyState';
import { useLayout } from '../../providers';
import { TableLicensesFilter } from './TableLicensesFilter';

type ListLicensesDto = {
  licenses: LicenseDto[];
  licenses_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

type IProps = {
  canCreate?: boolean;
};

export const Licenses = ({ canCreate = true }: IProps) => {
  const { t } = useTranslation();
  const { toastManager } = useToast();
  const routeParams = useParams();
  const { setTitle, setGoBackRoute } = useLayout();

  const getQueryVariables = () => ({
    where: {
      _or: [
        { name: { _ilike: `%${licenseName}%` } },
        { apikey: { _ilike: `%${licenseName}%` } },
      ],
      client_id: { _eq: routeParams.id },
    },
    limit: MaxItemsPag,
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [licenseName, setLicenseName] = useState<string>('');
  const [cloneLicense, setCloneLicense] = useState<boolean>();
  const [selectedLicense, setSelectedLicense] = useState<NewLicenseDto>();
  const { onInsertLicense, onEditLicense } = useLicense(getLicenses, {
    ...getQueryVariables(),
    offset: 0,
  });

  const onSeeDetail = (license: NewLicenseDto) => {
    setShowDetail(true);
    setSelectedLicense(license);
  };

  useEffect(() => {
    setTitle(t('Licenses list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const { data, loading, error, refetch, fetchMore } =
    useQuery<ListLicensesDto>(getLicenses, {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...getQueryVariables(),
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  const { scrollPosition } = useScrollPosition(
    RoutesName.licenseId,
    data?.licenses,
  );

  const hasMoreItems = (): boolean =>
    !!data && data?.licenses.length < data?.licenses_aggregate.aggregate.count;

  const handlePagination = async (): Promise<boolean> => {
    if (hasMoreItems() && !loading) {
      await fetchMore({
        variables: {
          offset: data?.licenses.length,
        },
      });
    }

    return Promise.resolve(true);
  };

  const forceUpdateQuery = () => {
    refetch(getQueryVariables());
  };

  const [activeLicense] = useMutation(activateLicense);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setLicenseName(event.target.value);
  };

  const onEdit = (license: NewLicenseDto) => {
    setSelectedLicense(license);
    setShowModal(true);
  };

  const onClone = (license: NewLicenseDto) => {
    setSelectedLicense({ ...license, apikey: undefined });
    setCloneLicense(true);
    setShowModal(true);
  };

  const onSave = async (license: NewLicenseDto, clone: boolean) => {
    if (selectedLicense && !clone) {
      await onEditLicense(license, selectedLicense.id as string);
    } else {
      onInsertLicense(license, routeParams.id);
    }

    setSelectedLicense(undefined);
    setShowModal(false);

    setTimeout(() => forceUpdateQuery(), 3000);
  };

  const onActivate = async (licenseId: string) => {
    await activeLicense({
      variables: { licenseId },
      update: (cache) => {
        const data: null | { licenses: LicenseDto[] } = cache.readQuery({
          query: getLicenses,
          variables: getQueryVariables(),
        });

        if (data) {
          cache.writeQuery({
            query: getLicenses,
            variables: getQueryVariables(),
            data: {
              ...data,
              licenses: data.licenses.map((item: LicenseDto) =>
                item.id === licenseId
                  ? { ...item, active: true }
                  : { ...item, active: false },
              ),
            },
          });
        }
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          duration: 3000,
          testId: 'toast-create-license-error',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('Activated License'),
          testId: 'toast-activate-license',
        });
      },
    });
  };

  const { emptyState } = useEmptyState({
    data: data?.licenses || [],
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(licenseName.length),
    onRetry: forceUpdateQuery,
  });

  return (
    <>
      <LicenseWrapper flexDirection="column">
        <TabSearchingHeader
          onCreate={
            canCreate
              ? () => {
                  setShowModal(true);
                  setSelectedLicense(undefined);
                }
              : undefined
          }
          onChange={handleSearchInput}
          permissionsCreate={[SCOPES.canWriteLicenses]}
          filterMenu={
            !canCreate ? <TableLicensesFilter onChange={() => {}} /> : undefined
          }
        />
        <TableLicenses
          licenses={data?.licenses}
          loading={loading}
          fetchMore={handlePagination}
          hasMore={hasMoreItems()}
          scrollPosition={scrollPosition}
          emptyStateData={emptyState}
          onActivate={onActivate}
          onSeeDetail={onSeeDetail}
          onEdit={onEdit}
          onClone={onClone}
        />
      </LicenseWrapper>
      {showModal && (
        <ModalNewLicense
          show={showModal}
          cloneLicense={cloneLicense}
          onChangeShow={(showModalNewLicense) => {
            setShowModal(showModalNewLicense);
            !showModalNewLicense && setCloneLicense(false);
          }}
          onSave={onSave}
          license={selectedLicense}
        />
      )}
      {showDetail && selectedLicense && (
        <ModalDetailLicense
          show={showDetail}
          onChangeShow={(show) => setShowDetail(show)}
          license={selectedLicense}
        />
      )}
    </>
  );
};
