import { gql } from '@apollo/client';

export const getPlatforms = gql`
  query getPlatforms {
    platforms {
      id
      name
    }
  }
`;
