import { useEffect, useMemo, useState } from 'react';
import {
  BUNDLE_ID,
  ENDDATE,
  LICENSE,
  ORIGINS,
  SERVICE_NAME,
} from '@cs/state/constants';
import {
  modalNewLicenseSchema,
  NewLicenseDto,
  LicenseService,
} from '@cs/state/model';

import {
  Dropdown,
  DropdownOption,
  FlexContainer,
  Input,
  InputDatePicker,
  ModalStateProps,
  TextArea,
  useTimezone,
  Label,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  JsonValuesDto,
  useClientServices,
  useComponentsClientsService,
  useFormatDate,
} from '../../hooks';
import { InputMultiple } from '../common';
import { CopyJsonInput } from './CopyJsonInput';
import { FormLicense } from './FormLicense';
import { ModalNewLicenseStyles } from './Styles';

type Props = ModalStateProps & {
  onSave(newUser: NewLicenseDto, clone: boolean): void;
  cloneLicense?: boolean;
  license?: NewLicenseDto;
  request?: boolean;
};

export const ModalNewLicense = ({
  show,
  onChangeShow,
  cloneLicense = false,
  onSave,
  license,
  request = false,
}: Props) => {
  const { t } = useTranslation();
  const { getDateTimezone } = useTimezone();
  const routeParams = useParams();

  const clientId = routeParams.id
    ? routeParams.id
    : license
      ? license.clientId
      : undefined;

  const { services } = useClientServices(clientId);
  const [serviceName, setServiceName] = useState<string>();
  const { getComponents, components } = useComponentsClientsService();
  const { formatDate } = useFormatDate();

  const methods = useForm<NewLicenseDto>({
    defaultValues: license
      ? {
          name: cloneLicense ? '' : license.name,
          origins: license.origins
            ? license.origins
            : license.origin
              ? [license.origin]
              : undefined,
          description: cloneLicense ? '' : license.description,
          endDate: license.endDate,
          bundleId: license.bundleId,
          service: license.serviceId,
          license: license.license,
        }
      : undefined,
    resolver: yupResolver(modalNewLicenseSchema),
  });

  const service = useWatch({
    name: 'service',
    control: methods.control,
  });

  useEffect(() => {
    if (service && services.length) {
      setServiceName(services.find((item) => item.id === service)?.name);
    }
  }, [services, service]);

  useEffect(() => {
    serviceName && methods.setValue(SERVICE_NAME, serviceName);

    if (!license) {
      if (serviceName === LicenseService.sdkWeb) {
        methods.setValue(BUNDLE_ID, '');
      } else {
        methods.setValue(ORIGINS, []);
      }
    }
  }, [serviceName]);

  const enableOrigin = useMemo(
    () =>
      serviceName
        ? serviceName === LicenseService.sdkWeb
          ? false
          : true
        : true,
    [serviceName],
  );

  const enableBundleId = useMemo(
    () =>
      serviceName
        ? serviceName === LicenseService.sdkIos ||
          serviceName === LicenseService.sdkAndroid
          ? false
          : true
        : true,
    [serviceName],
  );

  useEffect(() => {
    if (service) {
      getComponents({
        variables: {
          serviceId: service,
        },
      });
    }
  }, [service]);

  const serviceOptions: DropdownOption[] = services.map((item) => ({
    value: item.id,
    name: item.name,
  }));

  const title = useMemo(() => {
    if (cloneLicense) return 'Clone license';
    if (license && !request) return 'Edit license';
    return 'Create a new license';
  }, [cloneLicense, license, request]);

  const onCopyValues = (values: JsonValuesDto) => {
    methods.setValue(LICENSE, values.components);
    methods.setValue(ENDDATE, values.dateEnd);
    methods.setValue(BUNDLE_ID, values.bundleId);
  };

  return (
    <FormProvider {...methods}>
      <ModalNewLicenseStyles
        testId="modal-create-license"
        header={{
          iconName: 'PlusCircle',
          title: t(title),
        }}
        overlay
        footer={{
          closeButtonLabel: t('Cancel'),
          submitButtonLabel: t('Save'),
          onSubmitButton: methods.handleSubmit((data) =>
            onSave(
              { ...data, clientId, apikey: license?.apikey },
              cloneLicense,
            ),
          ),
        }}
        hasPadding
        overlayBlur
        show={show}
        onChangeShow={onChangeShow}
        isForm
        disableOnClickOutside
      >
        <FlexContainer as="form" flexDirection="column" rowGap="3.2">
          <Input
            {...methods.register('name')}
            placeholder={t('Licence name')}
            label={t('License name')}
            testId="license-name"
            errorLabel={
              methods.formState.errors.name?.message &&
              t(methods.formState.errors.name.message)
            }
          />
          <TextArea
            {...methods.register('description')}
            placeholder={t('Description')}
            testId="description"
            label={t('Description')}
            rows={4}
            errorLabel={
              methods.formState.errors.description?.message &&
              t(methods.formState.errors.description.message)
            }
          />
          <Controller
            name="endDate"
            control={methods.control}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={({ field }: any) => (
              <InputDatePicker
                {...field}
                testId="license-end-date"
                minDate={getDateTimezone(new Date().getTime()).toDate()}
                placeholder={t('End date')}
                value={field.value ? formatDate(field?.value) : undefined}
                label={t('End date')}
                errorLabel={
                  methods.formState.errors.endDate?.message &&
                  t(methods.formState.errors.endDate.message)
                }
              />
            )}
          />
          <Input
            {...methods.register('bundleId')}
            placeholder={t('For example com.facephi.net')}
            testId="bundle-id"
            label={t('Application Id')}
            disabled={enableBundleId}
            errorLabel={
              methods.formState.errors.bundleId?.message &&
              t(methods.formState.errors.bundleId.message)
            }
          />
          <Controller
            name="origins"
            control={methods.control}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={({ field }: any) => (
              <InputMultiple
                {...field}
                placeholder={'Add a valid url and press enter'}
                testId="origin"
                label={t('Origins')}
                disabled={enableOrigin}
              />
            )}
          />
          <Label fontSize="14">{t('Service')}</Label>
          <Controller
            name="service"
            control={methods.control}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={({ field }: any) => (
              <Dropdown
                {...field}
                options={serviceOptions}
                testId="service"
                placeholder={t('Select a service')}
                errorLabel={
                  methods.formState.errors.service?.message &&
                  t(methods.formState.errors.service.message)
                }
                disabled={license ? true : false}
              />
            )}
          />
          <Label fontSize="14">{t('Components')}</Label>
          <Controller
            name="license"
            control={methods.control}
            render={({ field }) => (
              <FormLicense
                components={components}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value={field.value as any}
                onChange={field.onChange}
                setError={methods.setError}
                clearErrors={methods.clearErrors}
                errorLabel={
                  methods.formState.errors.license?.message &&
                  t(methods.formState.errors.license.message)
                }
              />
            )}
          />
          <CopyJsonInput onChange={onCopyValues} />
        </FlexContainer>
      </ModalNewLicenseStyles>
    </FormProvider>
  );
};
