import { gql } from '@apollo/client';

export const modifyProfile = gql`
  mutation editProfile(
    $id: uuid!
    $given_name: String!
    $family_name: String!
    $email: String!
  ) {
    update_profiles_by_pk(
      pk_columns: { id: $id }
      _set: {
        given_name: $given_name
        family_name: $family_name
        email: $email
      }
    ) {
      name
      given_name
      family_name
      avatar
      name
      email
      id
    }
  }
`;
