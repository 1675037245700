import { setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { LayoutCorner } from '../layoutCorner';

export const LayoutCornerCS = styled(LayoutCorner)`
  a {
    color: ${setColorTheme('blue400')};
  }
`;

export const BrowserImage = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`;
