import { EmptyState } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const CardEmptyState = styled(EmptyState)`
  height: 100%;
  align-items: center;

  p {
    text-align: center;
  }
`;
