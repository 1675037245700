import { useState, useEffect } from 'react';
import { useClient } from '@cs/hooks';
import {
  AdditionalPropertiesDto,
  FormCompletedWith,
  LicenseService,
  Platforms,
} from '@cs/state/model';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export function useCompleteWith(
  aditionalProperties?: AdditionalPropertiesDto,
  onChange?: (value: string) => void,
) {
  const [newValue, setNewValue] = useState<string>();
  const { watch } = useFormContext();
  const routeParams = useParams();

  if (aditionalProperties?.completedWith) {
    if (aditionalProperties.completedWith === FormCompletedWith.clientName) {
      const { client } = useClient(routeParams.id);

      useEffect(() => {
        if (client.name !== '') {
          setNewValue(client.name);
        }
      }, [client]);
    }

    useEffect(() => {
      const subscription = watch((value) => {
        switch (aditionalProperties?.completedWith) {
          case FormCompletedWith.service: {
            setNewValue(Platforms[value.serviceName as LicenseService]);

            break;
          }
          case FormCompletedWith.bundleId:
            setNewValue(value.bundleId);
            break;
          case FormCompletedWith.endDate: {
            if (value.endDate) {
              const originalDate = new Date(value.endDate);

              const formatDate =
                originalDate.getFullYear() +
                '-' +
                ('0' + (originalDate.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + originalDate.getDate()).slice(-2);

              setNewValue(formatDate);
            }
            break;
          }
        }
      });
      return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
      if (newValue) {
        onChange && onChange(newValue);
      }
    }, [newValue]);
  }
}
