import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ModalAssignService, TabSearchingHeader } from '@cs/components';
import { useScrollPosition } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { MaxItemsPag, RoutesName } from '@cs/state/constants';
import { SCOPES, Service, ServiceDto } from '@cs/state/model';
import {
  insertComponentService,
  insertService,
  deleteClientComponentsService,
} from '@cs/state/mutations';
import { getServices } from '@cs/state/queries';
import { CardBase, useToast } from '@facephi/ui-react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';
import { TableServices } from './TableServices';

export const Services = () => {
  const { t } = useTranslation();
  const { toastManager } = useToast();
  const routeParams = useParams();
  const routeId = routeParams.id;
  const { setTitle, setGoBackRoute } = useLayout();
  const [serviceName, setServiceName] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<Service>();

  const getQueryVariables = () => ({
    clientId: routeId,
    name: `%${serviceName}%`,
    limit: MaxItemsPag,
  });

  const { data, loading, error, refetch, fetchMore } = useQuery<ServiceDto>(
    getServices,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...getQueryVariables(),
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const { scrollPosition } = useScrollPosition(
    RoutesName.licensesByService,
    data?.clients_services,
  );

  const handleInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setServiceName(value);
  };

  const [assignService] = useMutation(insertService);
  const [assignComponentService] = useMutation(insertComponentService);
  const [deleteComponentService] = useMutation(deleteClientComponentsService);

  const onEditService = (service: Service) => {
    setServiceSelected(service);
    setShowModal(true);
  };

  const onAssigned = async (service: Service) => {
    if (serviceSelected) {
      if (service.components) {
        await deleteComponentService({
          variables: { serviceId: serviceSelected.id },
        });

        const componentsServices = service.components.map((item) => ({
          service_id: serviceSelected.id,
          component_id: item,
        }));

        await assignComponentService({
          variables: {
            componentsServices,
          },
          onCompleted: () => {
            toastManager({
              type: 'success',
              message: t('Service successfully edited'),
              duration: 3000,
              testId: 'toast-edited-service',
            });
          },
        });
      }
    } else {
      await assignService({
        variables: {
          clientId: routeId,
          serviceId: service.id,
        },
        update: (cache, { data: { insert_clients_services_one } }) => {
          if (insert_clients_services_one && data) {
            const queryData = cloneDeep(data);
            queryData.clients_services.unshift(insert_clients_services_one);

            cache.writeQuery({
              query: getServices,
              variables: getQueryVariables(),
              data: queryData,
            });
          }
        },
        onCompleted: async (data) => {
          const serviceId = data.insert_clients_services_one.id;

          if (service.components) {
            const componentsServices = service.components.map((item) => ({
              service_id: serviceId,
              component_id: item,
            }));

            await assignComponentService({
              variables: {
                componentsServices,
              },
            });
          }

          toastManager({
            type: 'success',
            message: t('Service successfully created'),
            duration: 3000,
            testId: 'toast-create-service',
          });
        },
      });
    }

    setShowModal(false);
  };

  const forceUpdateQuery = () => {
    refetch(getQueryVariables());
  };

  const hasMoreItems = (): boolean =>
    !!data &&
    data?.clients_services.length <
      data?.clients_services_aggregate.aggregate.count;

  const handlePagination = async (): Promise<boolean> => {
    if (hasMoreItems() && !loading) {
      await fetchMore({
        variables: {
          offset: data?.clients_services.length,
        },
      });
    }

    return Promise.resolve(true);
  };

  useEffect(() => {
    setTitle(t('Services list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const { emptyState } = useEmptyState({
    data: data?.clients_services || [],
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(serviceName.length),
    onRetry: forceUpdateQuery,
  });

  return (
    <>
      <CardBase flexDirection="column" flex="1">
        <TabSearchingHeader
          onChange={handleInputChange}
          onCreate={() => {
            setServiceSelected(undefined);
            setShowModal(true);
          }}
          buttonLabel="Assign"
          permissionsCreate={[SCOPES.canWriteLicenses]}
        />
        <TableServices
          services={data?.clients_services}
          loading={loading}
          fetchMore={handlePagination}
          hasMore={hasMoreItems()}
          scrollPosition={scrollPosition}
          emptyStateData={emptyState}
          onEdit={onEditService}
        />
      </CardBase>
      {showModal && (
        <ModalAssignService
          show={showModal}
          routeId={routeId}
          service={serviceSelected}
          onSave={onAssigned}
          onChangeShow={(showModalAssignService) => {
            setShowModal(showModalAssignService);
          }}
        />
      )}
    </>
  );
};
