import { gql } from '@apollo/client';

export const getLicenses = gql`
  query listLicenses($where: licenses_bool_exp, $offset: Int, $limit: Int) {
    licenses(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
    ) {
      id
      name
      description
      apikey
      valid_until
      bundle_id
      origin
      origins
      active
      created_at
      licenses
      clients_service {
        id
        service {
          id
          name
        }
      }
    }
    licenses_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const getLicenseById = gql`
  query getLicenseService($id: uuid!) {
    licenses_by_pk(id: $id) {
      active
      id
      license
      name
      valid_until
      created_at
    }
  }
`;
