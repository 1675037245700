import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Language } from '@cs/state/model';
import { getLanguages } from '@cs/state/queries';
import { DropdownOption } from '@facephi/ui-react';

export function useLanguage() {
  const { data } = useQuery<{ languages: Language[] }>(getLanguages);

  const optionsLanguage: DropdownOption[] = useMemo(
    () =>
      data
        ? data.languages.map((item) => ({ value: item.code, name: item.name }))
        : [],
    [data],
  );

  return {
    optionsLanguage,
  };
}
