import { gql } from '@apollo/client';

export const apiKeysFragment = gql`
  fragment apiKeysFields on api_keys {
    name
    id
    updated_at
    created_at
    configuration
    api_key
  }
`;
