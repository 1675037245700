import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ModalAssignUser,
  TabSearchingHeader,
  UsersTable,
} from '@cs/components';
import { useBlockUser, useUsersPaginate } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { RoutesName } from '@cs/state/constants';
import { SCOPES, User } from '@cs/state/model';
import { insertUser } from '@cs/state/mutations';
import { CardBase, useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';

export const Users = () => {
  const { t } = useTranslation();
  const { setTitle, setGoBackRoute } = useLayout();
  const routeParams = useParams();
  const { toastManager } = useToast();

  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    handleSearch,
    variables,
    data,
    usersError,
    loading,
    search,
    total,
    currentPage,
    users,
    changePage,
    updateUsers,
  } = useUsersPaginate(routeParams.id as string);

  const { onHandleBlockUser } = useBlockUser(variables);

  const [createUser] = useMutation(insertUser);

  const onCreate = async (user: User) => {
    await createUser({
      variables: {
        user: {
          email: user.email,
          client_id: routeParams.id,
          first_name: user.firstName,
          last_name: user.lastName,
          password: user.password,
          metadata: user.metadata,
        },
        clientId: routeParams.id,
      },
      onError: (error) => {
        toastManager({
          type: 'error',
          message: error.message,
          duration: 3000,
          testId: 'toast-error-user',
        });
      },
      onCompleted: () => {
        updateUsers();
        toastManager({
          type: 'success',
          message: t('A new user has successfully added '),
          duration: 3000,
          testId: 'toast-create-user',
        });
      },
    });

    setShowModal(false);
  };

  useEffect(() => {
    setTitle(t('Users list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const { emptyState } = useEmptyState({
    data: data?.users,
    hasError: Boolean(usersError),
    isLoading: loading,
    isFilter: Boolean(search),
    onRetry: updateUsers,
  });

  return (
    <>
      <CardBase flex="1" flexDirection="column">
        <TabSearchingHeader
          onCreate={() => setShowModal(true)}
          onChange={handleSearch}
          permissionsCreate={[SCOPES.canWriteUsers]}
          buttonLabel="Assign User"
        />

        <UsersTable
          users={users}
          loading={loading}
          totalPages={total}
          changePage={changePage}
          currentPage={currentPage}
          emptyStateData={emptyState}
          onBlock={onHandleBlockUser}
        />
      </CardBase>

      {showModal && (
        <ModalAssignUser
          show={showModal}
          clientId={routeParams.id as string}
          onSave={onCreate}
          onChangeShow={(showModalCreateUser) =>
            setShowModal(showModalCreateUser)
          }
        />
      )}
    </>
  );
};
