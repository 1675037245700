import { useState, ChangeEvent, useMemo } from 'react';
import { MaxItemsPag } from '@cs/state/constants';

export function usePaginate() {
  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);

  const getQueryVariables = () => ({
    name: `%${search}%`,
    limit: MaxItemsPag,
  });

  const changePage = (page: number) => {
    setOffset((page - 1) * MaxItemsPag);
  };

  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    setOffset(0);
  };

  const currentPage = useMemo(
    () => offset / MaxItemsPag + 1,
    [offset, MaxItemsPag],
  );

  return {
    handleSearch,
    changePage,
    getQueryVariables,
    offset,
    search,
    currentPage,
  };
}
