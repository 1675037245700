export enum RestrictionsApiKey {
  none = 'none',
  urls = 'urls',
  ip = 'ip',
  android = 'android',
  ios = 'ios',
  services = 'services',
}

export type ApiKeyDto = {
  name: string;
  apiKey: string;
  restriction: RestrictionsApiKey;
  services: string[];
  bundleId: string;
  fingerprint: string;
};

export type APiKeyItemDto = {
  name: string;
  id: string;
  created_at: string;
  updated_at: string;
  api_key: string;
  configuration: { [key in RestrictionsApiKey]: { [key in string]: string } };
  api_keys_services: {
    service_id: string;
  }[];
};

export type AggregateDto = {
  aggregate: {
    count: number;
  };
};

export type ApiKeyResultsDto = {
  api_keys: APiKeyItemDto[];
  total: AggregateDto;
};
