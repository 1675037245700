import { gql } from '@apollo/client';

export const getTimezones = gql`
  query getTimezones {
    timezone(order_by: { timezone: asc }) {
      country_code
      timezone
    }
  }
`;
