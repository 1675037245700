import { ReactNode } from 'react';
import { CodeStatus, RoutesName } from '@cs/state/constants';
import { Button, FlexContainer, Label, LogoFacephi } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  LayoutError,
  LayoutErrorAnimation,
  LayoutErrorContent,
  LayoutErrorTitle,
} from './Styles';

type Props = {
  animationData: Record<string, unknown>;
  code: CodeStatus;
  title: string;
  message?: ReactNode;
  buttonText: string;
};

export const LayoutErrors = ({
  animationData,
  code,
  title,
  message,
  buttonText,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LayoutError>
      {code === CodeStatus.error500 && (
        <LayoutErrorAnimation animation={animationData} />
      )}

      <LayoutErrorContent
        flexDirection="column"
        alignItems="center"
        justifyContent={
          code === CodeStatus.error404 ? 'flex-start' : 'space-between'
        }
      >
        <LogoFacephi color="dark" type="withLabel" />
        <FlexContainer
          flexDirection="column"
          flex="1"
          alignItems="center"
          justifyContent={code === CodeStatus.error404 ? 'center' : 'flex-end'}
        >
          {code === CodeStatus.error404 && <img src="/404.svg" alt="404" />}

          <Label
            as="h1"
            fontSize="60"
            textAlign="center"
            semibold
            color="primary400"
          >
            {code}
          </Label>

          <LayoutErrorTitle
            fontSize="21"
            semibold
            textAlign="center"
            data-test="error-title"
          >
            {t(`${title}`)}
          </LayoutErrorTitle>

          {message && message}

          {code === CodeStatus.error404 ? (
            <Button
              testId="visit-dashboard"
              size="L"
              onClick={() => navigate(RoutesName.clients)}
            >
              {t(`${buttonText}`)}
            </Button>
          ) : (
            <Label fontSize="18">{t(`${buttonText}`)}</Label>
          )}
        </FlexContainer>
      </LayoutErrorContent>
    </LayoutError>
  );
};
