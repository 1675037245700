import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { listRolesDto, listUserRoleDto, RoleDto } from '@cs/state/model';
import { assignRole, updateRole } from '@cs/state/mutations';
import { getRoles, getUserRole } from '@cs/state/queries';
import {
  ButtonIcon,
  Chips,
  FlexContainer,
  IconName,
  useToast,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CardCollapsableRoleStyles } from './Styles';

type Props = {
  title: string;
  iconName?: IconName;
  id: string;
  onDelete(): void;
  onDefault(): void;
  active: boolean;
};

export const CardCollapsableRole = ({
  title,
  iconName,
  id,
  onDelete,
  onDefault,
  active,
}: Props) => {
  const routeParams = useParams();

  const { t } = useTranslation();
  const { toastManager } = useToast();

  const { data } = useQuery<listRolesDto>(getRoles, {
    variables: {
      clientId: routeParams.clientId,
      tenantName: title,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: dataUser } = useQuery<listUserRoleDto>(getUserRole, {
    variables: {
      userId: routeParams.userId,
      tenantId: id,
    },
  });

  const checkIsSelected = (roleId: string) => {
    if (dataUser) {
      const role = dataUser.role.find((role) => role.role_id === roleId);

      if (role) {
        return true;
      }
    }

    return false;
  };

  const [addRole] = useMutation(assignRole);
  const [saveRole] = useMutation(updateRole);

  const onAssignRole = useCallback(
    async (role: RoleDto) => {
      if (dataUser) {
        const userRole = dataUser.role.find(
          (role) => role.role_id === role.role_id,
        );

        userRole
          ? saveRole({
              variables: {
                userId: routeParams.userId,
                tenantId: id,
                roleId: userRole.role_id,
                role: {
                  role: role.name,
                  role_id: role.id,
                },
              },
              refetchQueries: [
                {
                  query: getUserRole,
                  variables: { userId: routeParams.userId, tenantId: id },
                },
              ],
              onCompleted: () => {
                toastManager({
                  type: 'success',
                  message: t('Assigned role '),
                  testId: 'role-assigned',
                });
              },
            })
          : addRole({
              variables: {
                role: {
                  role: role.name,
                  role_id: role.id,
                  tenant_id: id,
                  user_id: routeParams.userId,
                },
                refetchQueries: [
                  {
                    query: getUserRole,
                    variables: { userId: routeParams.userId, tenantId: id },
                  },
                ],
              },
            });
      }
    },
    [dataUser],
  );

  return data ? (
    <CardCollapsableRoleStyles
      title={title}
      iconLeft={iconName}
      withShadow
      rightHeaderChildren={
        <FlexContainer>
          {!active && (
            <ButtonIcon
              iconName="Star"
              labelTooltip={t('Mark as Default')}
              onClick={onDefault}
              variant="text"
            />
          )}
          <ButtonIcon
            iconName="LinkBreak"
            labelTooltip={t('Remove Tenant')}
            variant="text"
            onClick={onDelete}
          />
        </FlexContainer>
      }
    >
      <FlexContainer
        alignItems="center"
        columnGap="0.8"
        rowGap="0.8"
        wrap="wrap"
      >
        {data.roles.map((role) => (
          <Chips
            key={role.id}
            label={role.name}
            onClick={() =>
              checkIsSelected(role.id) ? null : onAssignRole(role)
            }
            isSelected={checkIsSelected(role.id)}
          />
        ))}
      </FlexContainer>
    </CardCollapsableRoleStyles>
  ) : null;
};
