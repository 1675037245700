import { useLazyQuery, useMutation } from '@apollo/client';
import { listRolesDto } from '@cs/state/model';
import { assignRole } from '@cs/state/mutations';
import { getRoles } from '@cs/state/queries';
import { DropdownSearchOption } from '@facephi/ui-react';

export function useRoles() {
  const [getRolesTenant, { loading, data: roles }] =
    useLazyQuery<listRolesDto>(getRoles);
  const [addRole] = useMutation(assignRole);

  const listRoles = async (tenantName: string, clientId: string) =>
    await getRolesTenant({
      variables: {
        clientId: clientId,
        tenantName: tenantName,
      },
    });

  const assignRoleDefault = async (
    tenants: DropdownSearchOption[],
    clientId: string,
    userId: string,
  ) => {
    await Promise.all(
      tenants.map(async (tenant) => {
        const { data } = await getRolesTenant({
          variables: {
            clientId: clientId,
            tenantName: tenant.name,
          },
        });

        const roles = data?.roles;

        if (roles) {
          if (roles.length) {
            const roleDefault = roles?.find(
              (item) => item.name === 'OPERATIONS',
            );

            if (roleDefault) {
              addRole({
                variables: {
                  role: {
                    role: roleDefault.name,
                    role_id: roleDefault.id,
                    tenant_id: tenant.value,
                    user_id: userId,
                  },
                },
              });
            }
          }
        }
      }),
    );
  };

  return {
    listRoles,
    roles,
    loading,
    assignRoleDefault,
  };
}
