export function useFormatDate() {
  const formatDate = (date: string | Date | undefined) => {
    if (!date) {
      return null;
    }

    const copyRegex = /\d{4}-\d{2}-\d{2}/;

    if (date instanceof Date) {
      return date;
    } else {
      if (copyRegex.test(date)) {
        return new Date(date);
      }
    }

    const format = date.split('-');

    return new Date(`${format[2]}-${format[1]}-${format[0]}`);
  };

  return {
    formatDate,
  };
}
