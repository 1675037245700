import { gql } from '@apollo/client';

export const insertLicense = gql`
  mutation createLicense($license: licenses_insert_input!) {
    insert_licenses_one(object: $license) {
      id
      name
      valid_until
      active
      created_at
    }
  }
`;

export const modifyLicense = gql`
  mutation editLicense($licenseId: uuid!, $license: licenses_set_input!) {
    update_licenses_by_pk(pk_columns: { id: $licenseId }, _set: $license) {
      id
      name
      valid_until
      active
      created_at
    }
  }
`;

export const removeLicense = gql`
  mutation deleteLicense($id: uuid!) {
    update_licenses_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const activateLicense = gql`
  mutation activateLicense($licenseId: uuid!) {
    activateLicense(licenseId: $licenseId) {
      id
    }
  }
`;
