import { useState, ChangeEvent } from 'react';

export function useSearch() {
  const [search, setSearch] = useState<string>('');

  const onHandleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  return {
    search,
    onHandleSearch,
  };
}
