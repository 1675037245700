import { gql } from '@apollo/client';

export const insertApplicationClient = gql`
  mutation insertApplication(
    $clientId: uuid!
    $application: applications_enum
  ) {
    insert_clients_applications_one(
      object: { application: $application, client_id: $clientId }
    ) {
      application
    }
  }
`;

export const deleteApplicationClient = gql`
  mutation deleteApplication($id: uuid!) {
    delete_clients_applications_by_pk(id: $id) {
      id
    }
  }
`;
