import {
  CardBase,
  CardBaseHeader,
  FlexContainer,
  Input,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const ProfileCard = styled(CardBase)`
  margin: 2rem 3rem 2rem 0;
`;

const img_size = 9.6;

export const ProfileCardHeader = styled(CardBaseHeader)`
  position: relative;

  img {
    position: absolute;
    width: ${img_size}rem;
    top: -${img_size / 2}rem;
    left: 4.2rem;
    border-radius: 50%;
  }
`;

export const ProfileCardContent = styled(FlexContainer)`
  padding: 5.8rem 4rem;
`;

export const ProfileCardContentSection = styled(FlexContainer)`
  &:last-of-type {
    margin-top: 4.4rem;
  }
`;

export const ProfileCardInput = styled(Input)`
  width: 34rem;
`;
