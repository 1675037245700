import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getAllClients, getAllServices } from '@cs/state/queries';
import {
  Chips,
  FilterMenu,
  FilterMenuContent,
  FilterMenuItem,
} from '@facephi/ui-react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

enum FilterType {
  clients = 'clients',
  services = 'services',
}

export type SelectedFilterLicensesType = { [key in FilterType]: string[] };

type Item = {
  id: string;
  name: string;
};
type ListClients = {
  [FilterType.clients]: Item[];
};
type ListServices = {
  [FilterType.services]: Item[];
};

type SelectedItemsType = Item & {
  type: FilterType;
};

export const TableLicensesFilter = ({
  onChange,
}: {
  onChange?: (items: SelectedFilterLicensesType) => void;
}) => {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<SelectedItemsType[]>([]);

  const { data: listClients } = useQuery<ListClients>(getAllClients);
  const { data: listServices } = useQuery<ListServices>(getAllServices);

  const handleItem = ({
    type,
    item: newItem,
    checked,
  }: {
    type?: FilterType;
    item: Item;
    checked?: boolean;
  }) => {
    if (checked && type) {
      setSelectedItems((prev: SelectedItemsType[]) => {
        const data = cloneDeep(prev);
        data.push({ ...newItem, type });
        return data;
      });
    } else if (!checked) {
      setSelectedItems((prev: SelectedItemsType[]) =>
        prev.filter((item) => item.id !== newItem.id),
      );
    }
  };

  const isChecked = (item: Item) =>
    !!selectedItems.find(({ id }) => id === item.id);

  useEffect(() => {
    let values: SelectedFilterLicensesType = {
      [FilterType.clients]: [],
      [FilterType.services]: [],
    };
    values = selectedItems.reduce((acc, { id, type }) => {
      acc[type].push(id);
      return acc;
    }, values);

    onChange && onChange(values);
  }, [selectedItems]);

  return (
    <>
      <FilterMenu>
        <FilterMenuContent title={t('Clients')}>
          {listClients?.clients.map((client) => (
            <FilterMenuItem
              key={client.id}
              checked={isChecked(client)}
              onChange={(event) =>
                handleItem({
                  type: FilterType.clients,
                  item: client,
                  checked: event.target.checked,
                })
              }
            >
              {client.name}
            </FilterMenuItem>
          ))}
        </FilterMenuContent>
        <FilterMenuContent title={t('Services')}>
          {listServices?.services.map((service) => (
            <FilterMenuItem
              key={service.id}
              checked={isChecked(service)}
              onChange={(event) =>
                handleItem({
                  type: FilterType.services,
                  item: service,
                  checked: event.target.checked,
                })
              }
            >
              {service.name}
            </FilterMenuItem>
          ))}
        </FilterMenuContent>
      </FilterMenu>
      {selectedItems.map((item: SelectedItemsType) => (
        <Chips
          closeable
          key={item.id}
          label={item.name}
          onClick={() => handleItem({ item })}
        />
      ))}
    </>
  );
};
