import { gql } from '@apollo/client';

export const getServices = gql`
  query listServices(
    $clientId: uuid!
    $name: String
    $offset: Int
    $limit: Int
  ) {
    clients_services(
      where: {
        client_id: { _eq: $clientId }
        service: { name: { _ilike: $name } }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
    ) {
      id
      service {
        id
        name
      }
    }
    clients_services_aggregate(
      where: {
        client_id: { _eq: $clientId }
        service: { name: { _ilike: $name } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getServicesByClient = gql`
  query listServicesByClient($clientId: uuid!) {
    services(
      where: { _not: { clients_services: { client_id: { _eq: $clientId } } } }
    ) {
      id
      name
    }
  }
`;

export const getAllServices = gql`
  query listAllServices {
    services {
      id
      license
      name
    }
  }
`;

export const getComponentServices = gql`
  query getComponentService($serviceId: uuid!) {
    components: components_services(
      where: { service_id: { _eq: $serviceId } }
    ) {
      component {
        id
        name
        schema
      }
    }
  }
`;

export const getClientComponentServices = gql`
  query getClientComponentsService($serviceId: uuid!) {
    components: clients_components_services(
      where: { service_id: { _eq: $serviceId } }
    ) {
      component {
        id
        name
        schema
      }
    }
  }
`;

export const getClientComponentsService = gql`
  query getClientComponentsService($serviceId: uuid!) {
    components: clients_components_services(
      where: { service_id: { _eq: $serviceId } }
    ) {
      component {
        id
        name
        schema
      }
    }
  }
`;

export const getServiceByLicense = gql`
  query listServiceByLicense($id: uuid!) {
    licenses(where: { id: { _eq: $id } }) {
      clients_service {
        service {
          id
          name
        }
      }
    }
  }
`;

export const getAllServicesByClient = gql`
  query getAllServicesByClient($clientId: uuid!) {
    services: clients_services(where: { client_id: { _eq: $clientId } }) {
      service {
        name
        id
        license
      }
      id
    }
  }
`;

export const getClientServiceByServiceId = gql`
  query getAllServicesByClient($clientId: uuid!, $serviceId: uuid!) {
    service: clients_services_by_pk(
      client_id: $clientId
      service_id: $serviceId
    ) {
      id
      clientId: client_id
      serviceId: service_id
    }
  }
`;
