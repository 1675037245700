import { useEffect, useMemo } from 'react';
import { useComponents, useCopyToClipboard } from '@cs/hooks';
import { RequestItemDto } from '@cs/state/model';
import { Icon, Button, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  StyledModalDetail,
  StyledModalContent,
  StyledLabelBold,
} from './Styles';

type IProps = {
  show: boolean;
  onChangeShow: (show: boolean) => void;
  requestId?: string;
  request?: RequestItemDto;
};

export const ModalDetail = ({ show, onChangeShow, request }: IProps) => {
  const { t } = useTranslation();

  const { copy } = useCopyToClipboard();
  const { components, getComponents } = useComponents();

  useEffect(() => {
    if (request) {
      getComponents({
        variables: {
          components: request.components,
        },
      });
    }
  }, [request]);

  const client = useMemo(() => {
    if (request) {
      if (request.client) {
        return {
          ...request.client,
          new: false,
        };
      }

      if (request.new_client) {
        return {
          name: request.new_client,
          new: true,
        };
      }
    }

    return {
      client: null,
      new: true,
    };
  }, [request]);

  return (
    <>
      {show && (
        <StyledModalDetail
          show={show}
          onChangeShow={onChangeShow}
          header={{
            iconName: 'MagnifyingGlassPlus',
            title: t('See Request detail'),
          }}
        >
          <StyledModalContent flexDirection="column">
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Api Key')}
            </StyledLabelBold>
            <Button
              variant="text"
              type="button"
              semiBold={false}
              onClick={() => (request ? copy(request.apikey) : undefined)}
            >
              {request?.apikey}
              <Icon iconName="Copy" size="16" />
            </Button>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Client')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {client.name}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Is the client new?')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {client.new ? t('Yes') : t('No')}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Technology')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {request?.technology}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Service')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {request?.service.name}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Components')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {components.map((item) => item.name).join(', ')}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Required SDK information')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {request?.bundleId && (
                <p>
                  <b>Bundle Id:</b>
                  {request?.bundleId}
                </p>
              )}
              {request?.origin && (
                <p>
                  <b>Origin:</b>
                  {request?.origin}
                </p>
              )}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Tracking required?')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {request?.trackingServer ? request.trackingServer : 'No'}
            </Label>
            <StyledLabelBold size={12} upperCase textAlign="center">
              {t('Comments')}
            </StyledLabelBold>
            <Label size={14} textAlign="center">
              {request?.comments}
            </Label>
          </StyledModalContent>
        </StyledModalDetail>
      )}
    </>
  );
};
