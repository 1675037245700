import { LogoFacephi } from '@facephi/ui-react';
import { LayoutCornerProps } from './model';
import {
  CornerBottomRight,
  CornerLeft,
  CornerTopRight,
  LayoutCornerModal,
  LayoutCornerStyles,
} from './Styles';

export const LayoutCorner = ({
  className,
  children,
  ...flexProps
}: LayoutCornerProps) => {
  return (
    <LayoutCornerStyles
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      rowGap="5"
    >
      <LogoFacephi type="withLabel" color="dark" />
      <LayoutCornerModal
        flexDirection="column"
        alignItems="center"
        className={className}
        {...flexProps}
      >
        {children}
      </LayoutCornerModal>
      <CornerLeft />
      <CornerTopRight />
      <CornerBottomRight />
    </LayoutCornerStyles>
  );
};
