import { gql } from '@apollo/client';
import { requestFragment } from '../fragments';

export const getRequests = gql`
  ${requestFragment}
  query listRquests(
    $search: String
    $active: Boolean!
    $offset: Int
    $limit: Int
  ) {
    items: request(
      where: { apikey: { _ilike: $search }, active: { _eq: $active } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      ...request
    }
    total: request_aggregate {
      aggregate {
        count
      }
    }
  }
`;
